import React, { useState } from 'react';
import { IoLogoWhatsapp } from "react-icons/io";
import { FaSkype, FaTelegram } from "react-icons/fa";
import * as emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RiInstagramFill } from "react-icons/ri";
import { BsFacebook } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";


const DiscussForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact_number: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if all fields are filled
    if (!formData.name || !formData.email || !formData.contact_number || !formData.message) {
      toast.error('Please fill out all fields.');
      return;
    }

    setIsSubmitting(true);

    try {
      await emailjs.send(
        'service_4ysj9df', // Replace with your EmailJS service ID
        'template_ippp15q', // Replace with your EmailJS template ID
        formData,
        'qC5bJcByx39OI01GB' // Replace with your EmailJS user ID
      );
      toast.success("Success! We'll get back to you soon. Thank you!");
      setFormData({
        name: '',
        email: '',
        contact_number: '',
        message: ''
      });
    } catch (error) {
      toast.error("Failed to send the message. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen lg:py-6 flex flex-col sm:py-1">
      <ToastContainer />
      <div className="flex flex-col sm:flex-row items-center justify-between">
        
        {/* Text Section */}
        <div className="flex-1 px-4 lg:px-12 lg:-mt-44">
          <h4 className="text-[18px] text-theme-purple lg:-mt-5 lg:mb-2">Contact Us</h4>
          <h1 className="text-5xl sm:text-5xl text-theme-blue font-bold leading-tight mb-5">
            We’re Just a Form Away – Let’s Connect!
          </h1>

          <p className="font-light text-xl text-gray-400 leading-relaxed mb-16">
            You can also <a href="mailto:hr@scodesoft.com" className="text-blue-600 hover:text-blue-800">mail us here</a>
          </p>

          <div className="flex space-x-4 -mt-10">
            <a href="https://wa.me/9629692276" target="_blank" rel="noopener noreferrer">
              <IoLogoWhatsapp 
                style={{ fontSize: '30px', color: 'gray', cursor: 'pointer' }} 
              />
            </a>

            <a href="http://instagram.com/scodesoftwaresolutions?igsh" target="_blank" rel="noopener noreferrer">
              <RiInstagramFill 
                style={{ fontSize: '30px', color: 'gray', cursor: 'pointer' }} 
              />
            </a>

            <a href="http://linkedin.com/company/scode-soft" target="_blank" rel="noopener noreferrer">
              <FaLinkedin 
                style={{ fontSize: '30px', color: 'gray', cursor: 'pointer' }} 
              />
            </a>
            <a href="http://facebook.com/share/wmzRPfEKTC3Cp2eP/" target="_blank" rel="noopener noreferrer">
              <BsFacebook 
                style={{ fontSize: '30px', color: 'gray', cursor: 'pointer' }} 
              />
            </a>
            <a href="http://www.youtube.com/@ScodeSoftwareSolutions" target="_blank" rel="noopener noreferrer">
              <FaYoutube 
                style={{ fontSize: '30px', color: 'gray', cursor: 'pointer' }} 
              />
            </a>
          </div>
        </div>
        
        {/* Form Section */}
        <div className="flex-1 mt-5 mb-10 lg:mb-0 lg:mt-0 lg:pr-20">
          <div className="relative py-3 sm:max-w-xl sm:mx-auto">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-teal-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
            <div className="text-white relative px-4 py-10 bg-gradient-to-r from-teal-500 to-blue-500 shadow-lg sm:rounded-3xl sm:p-20">
              <form onSubmit={handleSubmit}>
                <input
                  className="shadow mb-4 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Your name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                <input
                  className="shadow mb-4 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="email"
                  placeholder="Your email address"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <input
                  className="shadow mb-4 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Your contact number"
                  name="contact_number"
                  value={formData.contact_number}
                  onChange={handleChange}
                />
                <textarea
                  className="shadow mb-4 min-h-0 appearance-none border rounded h-64 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Explain about your project idea"
                  name="message"
                  style={{ height: '121px' }}
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>

                <div className="flex justify-between">
                  <input
                    className={`bg-white hover:bg-gray-100 text-blue-500 font-bold py-2 px-4 sm:px-6 rounded transition duration-300 ease-in-out transform hover:scale-105 ${isSubmitting ? 'cursor-not-allowed' : ''}`}
                    type="submit"
                    value={isSubmitting ? "Sending..." : "Send ➤"}
                    disabled={isSubmitting}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscussForm;
