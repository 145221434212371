
import { createContext, useEffect, useState } from "react";
import api from "../api/api";

// Create the context
export  const  JobsContext = createContext();


// Create the context provider component
export const JobsProvider = ({children}) => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    //Function to fetch all jobs from the API
    const fetchJobs = async () => {
        try {
            const response = await api.jobs.fetchJobs();
            setJobs(response);
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);

        }
    };

    // Fetch jobs when the component mounts
    useEffect(() => {
        fetchJobs();
    },[]);

    return(
        <JobsContext.Provider value={{jobs,loading, error}} >
            {children}
        </JobsContext.Provider>
    )

}



