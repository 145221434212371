import React from 'react';
import { Fade } from 'react-awesome-reveal';

import infotech from 'assets/images/Solution/it.png'; // Replace with actual path
import healthcare from 'assets/images/Solution/health.png'; // Replace with actual path
import retail from 'assets/images/Solution/retail.png'; // Replace with actual path
import hospital from 'assets/images/Solution/hospitality.png'; // Replace with actual path
import government from 'assets/images/Solution/govern.png'; // Replace with actual path
import food from 'assets/images/Solution/food.png'; // Replace with actual path
import education from 'assets/images/Solution/edu.png'; // Replace with actual path
import RealState from 'assets/images/Solution/real-estate.png'; // Replace with actual path

const tools = [
  { id: 1, name: 'Information Technology', img: infotech},
  { id: 2, name: 'Healthcare', img: healthcare },
  { id: 3, name: 'Retail & E-commerce', img: retail },
  { id: 4, name: 'Hospitality and Travel', img: hospital},
  { id: 5, name: 'Real Estate', img: RealState },
  { id: 6, name: 'Food & Beverage', img: food },
  { id: 7, name: 'Education', img: education },
  { id: 8, name: 'Government and Public Sector', img: government }, 
];

const HexagonGallery = () => {
  return (
    <section className='flex flex-col p-5'>
        <div className=' lg:px-12 w-full sm:w-4/5 lg:mb-6'>
            <Fade direction="up" triggerOnce>
                <h1 className="text-4xl text-theme-blue font-bold leading-tight lg:mb-6">
                Industries We Serve
                </h1>
            </Fade>
            <Fade direction="up" triggerOnce>
            <p className="font-light text-xl text-gray-400 leading-relaxed">
            We don’t just work across industries; we immerse ourselves in them to create tailor-made
            solutions that redefine what’s possible. Our expertise spans
                </p>
            </Fade>
        </div>
        
        <Fade direction="up" triggerOnce>
         <div className="hexagon-gallery mb-2 lg:mb-6">
         
            {tools.map((tool, index) => (
              
                <div
                key={tool.id}
                className={`hex border-2 hex-${index + 1}`}
                >
                <img src={tool.img} alt={tool.name} />
                <p className='lg:text-6xl font-semibold'>{tool.name}</p>
                </div>
            ))}
         
         </div>
        </Fade>
        
    </section>
  );
};

export default HexagonGallery;
