import React from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import { Fade } from 'react-awesome-reveal';

import { WiStars } from 'react-icons/wi';

const HeroServiceDetail = () => {
  const location = useLocation(); // Get the passed state data
  const { detail_short_title,detailImageUrl, detail_title, detail_description } = location.state || {}; // Destructure the passed data

  return (
    <section className="hero flex flex-col sm:flex-row items-center justify-center sm:justify-between px-5 sm:px-12 lg:px-14 mt-20 ">
      <div className="flex flex-col w-full sm:w-2/3 mb-2 -mt-12">

        <Fade direction="up" triggerOnce>
          <div className="flex items-center">
            <h4 className="text-[18px] text-theme-purple mr-2">{detail_short_title}</h4>
            <WiStars size={30} className="text-theme-purple" />
          </div>
        </Fade>

        <Fade direction="up" triggerOnce>
          <h1 className="text-3xl lg:text-5xl text-theme-blue font-bold leading-tight mb-6">
            {detail_title}
          </h1>
        </Fade>

        <Fade direction="up" triggerOnce >
          <p className="font-light text-xl text-gray-400 leading-relaxed">
            {detail_description}
          </p>
        </Fade>
      </div>

      <div className="w-full sm:w-1/2 flex justify-center sm:justify-end -mt-16">
        <Fade direction="up" triggerOnce>
          <img 
            src={detailImageUrl} 
            className="h-[500px] object-contain" 
            alt="Hero" 
          />
        </Fade>
      </div>
    </section>
  );
}

export default HeroServiceDetail;
