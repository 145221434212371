
import React, { Component } from 'react';

import Header from 'parts/Header';
import Footer from 'parts/Footer';
import AboutBanner from 'parts/AboutBanner';
import AboutScode from 'parts/AboutScode';
import VisionMission from 'parts/VisionMission';
import WhoWeAre from 'parts/WhoWeAre';
import AboutFewReason from 'parts/AboutFewReason';

import {
  ChooseReason
} from "json/landingPageData";



export default class AboutPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Header />
        <AboutBanner />
        <AboutScode />
        <VisionMission />
        <WhoWeAre />
        <AboutFewReason data={ChooseReason} />
       
       
        <Footer />
      </>
    );
  }
}
