import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { WiStars } from "react-icons/wi";

export default function InternshipHero() {
  return (
    <section className="hero flex lg:items-center lg:justify-center lg:text-center">
      <div className="w-full sm:w-4/5 flex flex-col mb-5 sm:mb-0 sm:px-12 sm:-mt-2">
        <Fade direction="up" triggerOnce>
          <div className="flex lg:items-center lg:justify-center sm:justify-start mb-5">
            <h4 className="text-2xl text-theme-purple mr-2">Careers</h4>
            <WiStars size={35} className="text-theme-purple" />
          </div>
        </Fade>
        <Fade direction="up" triggerOnce>
          <h1 className="text-5xl text-theme-blue font-bold leading-tight mb-5">
            Discover your path and make a difference
          </h1>
        </Fade>
        <Fade direction="up" triggerOnce delay={50}>
          <p className="font-light text-xl text-gray-400 leading-relaxed">
          Advance your skills with hands-on internship opportunities that offer real-world experience in web and mobile development.
          </p>
        </Fade>
      </div>
    </section>
  );
}
