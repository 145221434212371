import React from 'react';
import { useLocation } from 'react-router-dom'; // Make sure to install and import react-router-dom if not already done
import { Fade } from 'react-awesome-reveal';
import { WiStars } from "react-icons/wi";

// Component to handle the layout of each image, title, and description
const ImageContent = ({ image, index }) => {
  const isEven = index % 2 === 0; // Check if the index is even or odd

  return (
    <div className={`flex flex-col lg:flex-row items-center my-6 ${isEven ? '' : 'lg:flex-row-reverse'}`}>
      {/* Image Section */}
      <div className="w-full lg:w-2/3">
        <Fade direction={isEven ? 'left' : 'right'} triggerOnce>
          <img
            src={image.imageUrl}
            alt={image.title}
            className="w-full h-auto"
          />
        </Fade>
      </div>

      {/* Content Section */}
      <div className="w-full lg:w-3/4 flex flex-col p-5  lg:p-12">
        <Fade direction={isEven ? 'right' : 'left'} triggerOnce>
          <h2 className="text-2xl font-bold text-theme-blue mb-2">{image.title}</h2>
          <p className="font-light text-gray-400 text-lg">{image.description}</p>
        </Fade>
      </div>
    </div>
  );
};

const ProductDetail = () => {
  const location = useLocation();
  const {images } = location.state || {}; // Access title, description, and images from location.state

  return (
    <section className="flex flex-col w-11/12 lg:w-11/12 pt-5 lg:pt-12 lg:mx-auto mx-auto">
        <div >
            <Fade direction="up" triggerOnce>
                <h1 className="text-[25px] lg:text-4xl text-theme-blue font-bold leading-tight mb-5  sm:text-left">
                  Blueprints to Brilliance
                </h1>
            </Fade>
        </div>
      {images && images.map((image, index) => (
        <ImageContent key={image.id} image={image} index={index} />
      ))}
    </section>
  );
};

export default ProductDetail;
