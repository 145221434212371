
import { createContext, useEffect, useState } from "react";
import api from "../api/api";



// Create the context
export  const  ProductsContext = createContext();


// Create the context provider component
export const ProductsProvider = ({children}) => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    //Function to fetch all products from the API
    const fetchProducts = async () => {
        try {
            const response = await api.products.fetchProducts();
            console.log("productssss",products)
            setProducts(response);
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);

        }
    };

    // Fetch Products when the component mounts
    useEffect(() => {
        fetchProducts();
    },[]);

    return(
        <ProductsContext.Provider value={{products,loading, error}} >
            {children}
        </ProductsContext.Provider>
    )

}



