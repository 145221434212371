import React, { useState } from "react";
import { motion } from "framer-motion";
import { useLocation } from 'react-router-dom';
import * as SiIcons from "react-icons/si"; // Import all Si icons
import * as FaIcons from "react-icons/fa"; // Import all FontAwesome icons
import * as TbIcons from "react-icons/tb"; // Import all Tabler Icons
import * as VscIcons from "react-icons/vsc";


export default function ServiceTechIcon() {
    const location = useLocation();
    const tech = location.state?.tech || []; // Ensure tech is defined
    const { id } = location.state || {};
    const [currentIndex, setCurrentIndex] = useState(0);

    // Function to go to the next feedback
    const nextFeedback = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % tech.length);
    };

    // Function to go to the previous feedback
    const previousFeedback = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + tech.length) % tech.length);
    };

    // Check if tech has items and id is 8
    if (id === 8 && tech.length > 0) {
        return (
            <>
                <div className="pt-1 py-2 container mx-auto lg:px-44 mb-24 lg:-mt-1 -mt-24">
                    <div className="flex  items-center justify-center p-4">
                        <button 
                            onClick={previousFeedback} 
                            className="p-2 text-gray-600 hover:bg-gray-200 rounded-l-lg"
                        >
                            &#10094; {/* Left Arrow */}
                        </button>

                        <div className="flex flex-col items-center text-center bg-white shadow-lg rounded-lg p-6 mx-4">
                            <div className="flex items-center space-x-4 mb-4">
                                <VscIcons.VscFeedback className="h-10 w-10 text-gray-600" />
                            </div>

                            <p className="text-xl italic text-gray-500 mt-2 transition-transform transform hover:scale-105">
                                {tech[currentIndex].name || 'No feedback provided'}
                            </p>
                        </div>

                        <button 
                            onClick={nextFeedback} 
                            className="p-2 text-gray-600 hover:bg-gray-200 rounded-r-lg"
                        >
                            &#10095; {/* Right Arrow */}
                        </button>
                    </div>
                </div>
            </>
        );
    }


    if (!tech.length) {
        return <></>; // Fallback message if tech data is missing
    }

    return (
        <div className="pt-1 py-8 container mx-auto overflow-hidden lg:px-5 mb-24">
            <div className="flex MyGradient">

            <motion.div
                initial={{ x: 0 }}
                animate={{ x: "-100%" }}
                transition={{ duration: 15, repeat: Infinity, ease: "linear" }}
                className="flex flex-shrink-0"
                >
                {tech.map((item) => {
                    const SiIconComponent = SiIcons[item.icon]; // Check in SiIcons
                    const FaIconComponent = FaIcons[item.icon]; // Check in FaIcons
                    const TbIconComponent = TbIcons[item.icon]; // Check in TbIcons

                    return (
                    <div key={item.id} className="flex flex-col items-center pr-14">
                        {SiIconComponent ? (
                        <SiIconComponent className="h-16 w-16" />
                        ) : FaIconComponent ? (
                        <FaIconComponent className="h-16 w-16" />
                        ) : TbIconComponent ? (
                        <TbIconComponent className="h-16 w-16" />
                        ) : (
                        <span className="h-12 w-12 text-gray-400">Icon not found</span>
                        )}
                        <p className="text-center text-sm font-medium text-gray-700 mt-2">{item.name}</p>
                    </div>
                    );
                })}
            </motion.div>



            <motion.div
                initial={{ x: 0 }}
                animate={{ x: "-100%" }}
                transition={{ duration: 15, repeat: Infinity, ease: "linear" }}
                className="flex flex-shrink-0"
                >
                {tech.map((item) => {
                    const SiIconComponent = SiIcons[item.icon]; // Check in SiIcons
                    const FaIconComponent = FaIcons[item.icon]; // Check in FaIcons
                    const TbIconComponent = TbIcons[item.icon]; // Check in TbIcons

                    return (
                    <div key={item.id} className="flex flex-col items-center pr-14">
                        {SiIconComponent ? (
                        <SiIconComponent className="h-16 w-16" />
                        ) : FaIconComponent ? (
                        <FaIconComponent className="h-16 w-16" />
                        ) : TbIconComponent ? (
                        <TbIconComponent className="h-16 w-16" />
                        ) : (
                        <span className="h-12 w-12 text-gray-400">Icon not found</span>
                        )}
                        <p className="text-center text-sm font-medium text-gray-700 mt-2">{item.name}</p>
                    </div>
                    );
                })}
                </motion.div>


                <motion.div
                    initial={{ x: 0 }}
                    animate={{ x: "-100%" }}
                    transition={{ duration: 15, repeat: Infinity, ease: "linear" }}
                    className="flex flex-shrink-0"
                    >
                    {tech.map((item) => {
                        const SiIconComponent = SiIcons[item.icon]; // Check in SiIcons
                        const FaIconComponent = FaIcons[item.icon]; // Check in FaIcons
                        const TbIconComponent = TbIcons[item.icon]; // Check in TbIcons

                        return (
                        <div key={item.id} className="flex flex-col items-center pr-14">
                            {SiIconComponent ? (
                            <SiIconComponent className="h-16 w-16" />
                            ) : FaIconComponent ? (
                            <FaIconComponent className="h-16 w-16" />
                            ) : TbIconComponent ? (
                            <TbIconComponent className="h-16 w-16" />
                            ) : (
                            <span className="h-12 w-12 text-gray-400">Icon not found</span>
                            )}
                            <p className="text-center text-sm font-medium text-gray-700 mt-2">{item.name}</p>
                        </div>
                        );
                    })}
                    </motion.div>

            </div>
        </div>
    );
}
