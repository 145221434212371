import React from 'react'
import { Fade } from 'react-awesome-reveal';
import problem from 'assets/images/Solution/problem-solve.png';

import { PiStarFourFill } from "react-icons/pi";

const WhoWeServe = () => {
  return (
    <section className="hero flex flex-col sm:flex-row items-center justify-center sm:justify-between px-5 sm:px-12 lg:px-12 mt-6 ">
       

        <div className="w-full sm:w-1/2 flex justify-center sm:justify-end mt-4">
            <Fade direction="up" triggerOnce>
            <div class="p-6 mt-4">
                    <ol class="relative border-s border-gray-200 dark:border-gray-700">                  
                        <li class="mb-4 ms-6">            
                            <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                            <PiStarFourFill className='text-theme-blue' />
                            </span>
                            <h3 class="flex items-center mb-1 text-lg font-semibold text-theme-blue dark:text-white">User Engagement Dilemma? </h3>
                            <p class="mb-4 text-base font-normal text-gray-400 dark:text-gray-400">We design captivating mobile experiences that turn visitors into loyal customers.</p>
                        </li>

                        <li class="mb-4 ms-6">
                            <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                            <PiStarFourFill className='text-theme-blue' />
                            </span>
                            <h3 class="mb-1 text-lg font-semibold text-theme-blue dark:text-white">Workflow Woes?</h3>
                            <p class="text-base font-normal text-gray-400 dark:text-gray-400">Our full-stack solutions streamline operations and boost productivity.</p>
                        </li>

                        <li class="mb-4 ms-6">
                            <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                            <PiStarFourFill className='text-theme-blue' />
                            </span>
                            <h3 class="mb-1 text-lg font-semibold text-theme-blue dark:text-white">Customer Acquisition Struggles?</h3>
                            <p class="text-base font-normal text-gray-400 dark:text-gray-400"> We develop apps with strategic marketing to attract and retain your audience.</p>
                        </li>

                        <li class="mb-4 ms-6">
                            <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                            <PiStarFourFill className='text-theme-blue' />
                            </span>
                            <h3 class="mb-1 text-lg font-semibold text-theme-blue dark:text-white">Design Deadlocks?</h3>
                            <p class="text-base font-normal text-gray-400 dark:text-gray-400">Our UI/UX expertise ensures smooth project flow from concept to launch.</p>
                        </li>

                        <li class="mb-4 ms-6">
                            <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                            <PiStarFourFill className='text-theme-blue' />
                            </span>
                            <h3 class="mb-1 text-lg font-semibold text-theme-blue dark:text-white">Time-to-Market Delays?</h3>
                            <p class="text-base font-normal text-gray-400 dark:text-gray-400">We use agile methods to accelerate development and reduce time-to-market.</p>
                        </li>

                        <li class="mb-4 ms-6">
                            <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                            <PiStarFourFill className='text-theme-blue' />
                            </span>
                            <h3 class="mb-1 text-lg font-semibold text-theme-blue dark:text-white">Legacy System Limitations? </h3>
                            <p class="text-base font-normal text-gray-400 dark:text-gray-400">We modernize outdated systems to keep your business competitive.</p>
                        </li>

                        <li class="mb-4 ms-6">
                            <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                            <PiStarFourFill className='text-theme-blue' />
                            </span>
                            <h3 class="mb-1 text-lg font-semibold text-theme-blue dark:text-white">Innovation Stagnation? </h3>
                            <p class="text-base font-normal text-gray-400 dark:text-gray-400">We bring fresh ideas and cutting-edge tech to revitalize your digital strategy.
                            </p>
                        </li>

                        <li class="mb-4 ms-6">
                            <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                            <PiStarFourFill className='text-theme-blue' />
                            </span>
                            <h3 class="mb-1 text-lg font-semibold text-theme-blue dark:text-white">Integration Hurdles?</h3>
                            <p class="text-base font-normal text-gray-400 dark:text-gray-400">We integrate systems seamlessly for a unified, efficient workflow.</p>
                        </li>

                        <li class="mb-4 ms-6">
                            <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                            <PiStarFourFill className='text-theme-blue' />
                            </span>
                            <h3 class="mb-1 text-lg font-semibold text-theme-blue dark:text-white">Performance Bottlenecks?</h3>
                            <p class="text-base font-normal text-gray-400 dark:text-gray-400">We optimize software and infrastructure for fast and smooth user experiences.                            </p>
                        </li>
                    
                       
                    </ol>
                </div>
            </Fade>
        </div>

        <div className="flex flex-col w-full sm:w-1/2 mb-10 mt-12 lg:ml-14 ">
            <Fade direction="up" triggerOnce>
                <h1 className="text-4xl text-theme-blue font-bold leading-tight mb-6">
                 Problems We Solve
                </h1>
            </Fade>

            <Fade direction="up" triggerOnce delay={400}>
                <p className="font-light text-xl text-gray-400 leading-relaxed">
                Innovation is born from solving problems others deem unsolvable. At Scode, we tackle the tough
                challenges head-on
                </p>
            </Fade>
            <Fade direction="up" triggerOnce>
                <img 
                src={problem} 
                className="h-[500px] object-contain " 
                alt="Hero" 
                />
            </Fade>
        </div>
    </section>
  )
}

export default WhoWeServe