import React from 'react';
import { useLocation } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import { WiStars } from "react-icons/wi";

const ProductDetailHero = () => {
    const location = useLocation();
    const { title, title_desc,short_description } = location.state;

    return (
        <section className="hero flex lg:items-center lg:justify-center lg:text-center relative">
            <div className="w-full lg:w-full flex flex-col mb-5 sm:mb-0 sm:px-12 sm:-mt-2">
                <Fade direction="up" triggerOnce>
                    <div className="flex lg:items-center lg:justify-center mb-5">
                        <h4 className="text-[18px] lg:text-2xl text-theme-purple mr-2">{title}</h4>
                         <WiStars size={window.innerWidth >= 1024 ? 35 : 20} className="text-theme-purple" />
                        <h4 className='text-[18px] lg:text-2xl text-theme-purple mr-2'>{title_desc}</h4>
                    </div>
                </Fade>

                <Fade direction="up" triggerOnce>
                    <h1 className="text-3xl lg:text-4xl text-theme-blue font-bold leading-tight mb-5 lg:text-center sm:text-left">
                        {short_description}
                    </h1>
                </Fade>

            </div>

           
        </section>
    );
};

export default ProductDetailHero;
