// src/context/ActiveProjectContext.js
import React, { createContext, useState, useEffect } from "react";
import api from "../api/api"; // Adjust the import path as needed

// Create the context
export const ActiveProjectContext = createContext();

// Create the context provider component
export const ActiveProjectProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch all Active projects from the API
  const fetchActiveProjects = async () => {
    setLoading(true); // Start loading
    try {
      const response = await api.active_projects.fetchActiveProjects();
      setProjects(response); // Set projects
    } catch (err) {
      setError(err.message); // Handle error
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Fetch projects when the component mounts
  useEffect(() => {
    fetchActiveProjects();
  }, []);

  return (
    <ActiveProjectContext.Provider value={{ projects, loading, error }}>
      {children}
    </ActiveProjectContext.Provider>
  );
};
