// src/api/api.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL || "https://api.scodesoft.com/";

// http://66.179.243.246/ 

// https://api.scodesoft.com/

// Define base API URLs
const SERVICES_API_URL = `${API_URL}api/servicess/`;
const ACTIVE_PROJECTS_API_URL = `${API_URL}api/active-projects/`; // New Projects API URL
const PRODUCTS_API_URL = `${API_URL}api/products/`;
const INTERNSHIP_API_URL = `${API_URL}api/internships/`;
const JOBS_API_URL = `${API_URL}api/jobs/`;
const CREATE_JOB_API_URL = `${API_URL}api/job-application/`;
const BLOGS = `${API_URL}api/blogs/`;
const USER_REGISTER = `${API_URL}api/register/`;
const QA = `${API_URL}api/qa/`;

// Handle Axios response and errors
const handleResponse = (response) => {
  return response.data;
};

// Handle Axios error response
const handleError = (error) => {
  let message = "An error occurred"; // Default error message
  // Check if the error response exists
  if (error.response) {
    // If there's a server response, extract the message
    message = error.response.data?.message || error.message || message;
  } else if (error.request) {
    // If the request was made but no response was received
    message = "No response from server. Please check your network connection.";
  } else {
    // If there was an error setting up the request
    message = error.message;
  }
  throw new Error(message);
};



// API functions for Services
const servicesApi = {
  fetchServices: async () => {
    try {
      const response = await axios.get(SERVICES_API_URL);
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  fetchServiceById: async (id) => {
    try {
      const response = await axios.get(`${SERVICES_API_URL}/${id}`);
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  createService: async (service) => {
    try {
      const response = await axios.post(SERVICES_API_URL, service, {
        headers: { 'Content-Type': 'application/json' },
      });
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  // Add more service-related methods as needed
};

// API functions for Active Projects
const activeProjectsApi = {
  fetchActiveProjects: async () => {
    try {
      const response = await axios.get(ACTIVE_PROJECTS_API_URL);
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  fetchActiveProjectById: async (id) => {
    try {
      const response = await axios.get(`${ACTIVE_PROJECTS_API_URL}/${id}`);
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  createActiveProject: async (project) => { // Renamed method to createActiveProject
    try {
      const response = await axios.post(ACTIVE_PROJECTS_API_URL, project, {
        headers: { 'Content-Type': 'application/json' },
      });
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  // Add more project-related methods as needed
};


// API Function for Products

const productsApi = {

  fetchProducts: async () => {
    try {
      const response = await axios.get(PRODUCTS_API_URL);
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

};


const jobApplyApi = {

  // New method for submitting a job application
  createJobApplication: async (formData) => {
    try {
      const response = await axios.post(CREATE_JOB_API_URL, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }, // Set the correct Content-Type
      });
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },
};


// API Function for User Register

const userRegisterApi = {

  // New method for submitting a 
  createJobApplication: async (formData) => {
    try {
      const response = await axios.post(USER_REGISTER, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }, // Set the correct Content-Type
      });
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },
};



// API Function for User QA

const qaApi = {

  createQaApplication: async (formData) => {
    try {
      const response = await axios.post(QA, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }, // Set the correct Content-Type
      });
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },
};


// API Function for Internship

const internshipApi = {

  fetchInternship: async () => {
    try {
      const response = await axios.get(INTERNSHIP_API_URL);
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

};

// API Function for job

const jobsApi = {

  fetchJobs: async () => {
    try {
      const response = await axios.get(JOBS_API_URL);
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

};


// API Function for blogs

const blogsApi = {

  fetchBlogs: async () => {
    try {
      const response = await axios.get(BLOGS);
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  // New function to like a blog post
  likeBlogPost: async (blogPostId) => {
    try {
        const response = await axios.post(`${API_URL}api/posts/${blogPostId}/like/`);
        return handleResponse(response);
    } catch (error) {
        handleError(error);
      }
  },

  getViewCount: async (blogPostId) => {
      try {
          const response = await axios.post(`${API_URL}api/posts/${blogPostId}/view/`);
          return handleResponse(response);
      } catch (error) {
          handleError(error);
        }
    },

  // Add Comment API function
  addComment: async (commentData) => {
    try {
      const response = await axios.post(`${API_URL}api/add-comment/`, commentData);
      return response.data;
    } catch (error) {
      console.error('Error adding comment:', error);
      throw error;
    }
  },

  fetchComments: async (blogPostId) => {
    try {
      const response = await axios.get(`${API_URL}api/comments/${blogPostId}/`);
      return response.data.data; // Extract comments from response
    } catch (error) {
      console.error('Error fetching comments:', error);
      throw error;
    }
  },
  

};



// Export all APIs as a single object
const api = {
  services: servicesApi,
  active_projects: activeProjectsApi,
  products:productsApi,
  internships:internshipApi,
  jobs:jobsApi,
  jobapply:jobApplyApi,
  blogs:blogsApi,
  regsiter:userRegisterApi,
  question_and_answer:qaApi,
};

export default api;
