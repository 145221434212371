
import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { FaSuitcase } from 'react-icons/fa';
import { FaLocationDot } from 'react-icons/fa6';
import { MdOutlineAccessTime } from "react-icons/md";
import { SiGoogleclassroom } from "react-icons/si";
import { FaHourglassHalf } from "react-icons/fa";

import { useNavigate } from 'react-router-dom';

export default function InternshipPost({ data }) {
  const navigate = useNavigate();

  const handleApplyClick = (job) => {
    navigate(`/internship-apply/${job.id}`, { state: job });
  };

  return (
    <div className="">
      <div className="container mx-auto p-5 lg:p-14">
        <Fade direction="right" triggerOnce>
          <h1 className="text-4xl text-theme-blue lg:text-center font-bold">Kickstart Your Internship Adventure! </h1>
        </Fade>
        <Fade direction="left" triggerOnce>
          <p className="font-light text-lg text-gray-400 lg:text-center mb-5">
          We’re excited to support you every step of the way!
          </p>
        </Fade>

        <div className="grid grid-rows-3  lg:px-2 gap-6 lg:gap-8 sm:grid-cols-3 sm:grid-rows-1 sm:gap-6 xl:gap-16">
          {data.map((item, index) => (
            <Fade direction={item.animation} delay={50 * index} key={item.id} triggerOnce>
              <div>
                <div className="bg-white group rounded-2xl shadow-2xl border transform transition duration-500 hover:scale-105 cursor-pointer p-6">
                  <h2 className="text-theme-blue text-xl py-2 rounded-b-2xl mb-4">{item.title}</h2>
                  <div className="text-left">
                    <div className="flex items-center mb-2">
                      <MdOutlineAccessTime  className="mr-2 text-theme-blue" />
                      
                      <p className="text-gray-600">Duration: {item.duration}</p>
                    </div>
                    <div className="flex items-center mb-2">
                      <SiGoogleclassroom className="mr-2 text-theme-blue" />
                      <p className="text-gray-600">Class Mode: {item.class_mode}</p>
                    </div>
                    <div className="flex items-center mb-2">
                   
                      <FaHourglassHalf className="mr-2 text-theme-blue"/>

                      <p className="text-gray-600">Class Hour: {item.class_hour}</p>
                    </div>

                    <div className="flex items-center mb-4">
                      <FaLocationDot className="mr-2 text-theme-blue" />
                      <p className="text-gray-600">{item.location}</p>
                    </div>
                    <button
                      className="bg-theme-purple text-white px-4 py-2 rounded-full transition duration-300"
                      onClick={() => handleApplyClick(item)}
                    >
                      Apply Now
                    </button>
                  </div>
                </div>
              </div>
            </Fade>
          ))}
        </div>
      </div>
    </div>
  );
}






