/* eslint-disable import/extensions */
// Services Img Imports 
import Web from '../assets/images/Services/web_development.png';
import Android from '../assets/images/Services/android1.png';
import IOS from '../assets/images/Services/iOS_App1.png';
import Backend from '../assets/images/Services/backend3.png';
import Hybrid from '../assets/images/Services/Hybrid2.png';
import UIUX from '../assets/images/Services/ui_ux1.png';
import DigitalMarketing from '../assets/images/Services/Dmarketing.png';
import Internship from '../assets/images/Services/intership.png';

import WebDetail from 'assets/images/Services/ServiceDetail/web.png' ;
import AndroidDetail from 'assets/images/Services/ServiceDetail/android-3.png' ;
import IosDetail from 'assets/images/Services/ServiceDetail/ios-3.png' ;
import BackendDetail from 'assets/images/Services/ServiceDetail/backend.png' ;
import HybridDetail from 'assets/images/Services/ServiceDetail/hybrid-2.png' ;
import UIUXDetail from 'assets/images/Services/ServiceDetail/ui-ux.png';
import DMDetail from 'assets/images/Services/ServiceDetail/dm-2.png' ;
import InternShipDetail from 'assets/images/Services/ServiceDetail/intern-3.png' ;


// Why Choose Us
import vision from '../assets/images/WhyChooseus/vision.png';
import community from '../assets/images/WhyChooseus/community.png';
import track from '../assets/images/WhyChooseus/track-record.png';
import quality from '../assets/images/WhyChooseus/quality.png';
import talent from '../assets/images/WhyChooseus/talent.png';
import expertise from '../assets/images/WhyChooseus/expertise.png';

// Testimonials
import Sasha from '../assets/images/Services/Dmarketing1.png';
import Reiner from '../assets/images/Services/Dmarketing1.png';
import Kruger from '../assets/images/Services/Dmarketing1.png';


// current Project
import DeliverySG from '../assets/images/CurrentProject/DeliverySG1.png';
import iPoll from '../assets/images/CurrentProject/iPoll2.png';

import AstroHub from '../assets/images/CurrentProject/AstroHub1.png';
import AstroHubLogo from '../assets/images/Product/AstrohubHD.png';
import DeliverySGLogo from '../assets/images/Product/DeliverSGRider.png';
import iPollLogo from '../assets/images/Product/IpollHD.jpeg';

// current project logo
import beat from '../assets/images/Product/beat.png';


// TeamMembers
import CEO from '../assets/images/TeamMembers/CEO.jpg';
import HRD from '../assets/images/TeamMembers/HRD.jpg';
import Finance from '../assets/images/TeamMembers/Finance.jpg';
import ProjectManager from '../assets/images/TeamMembers/Project-manager.jpg';
import Frontend1 from '../assets/images/TeamMembers/Frontend1.jpg';
import Frontend2 from '../assets/images/TeamMembers/Frontend2.jpg';
import Backend1 from '../assets/images/TeamMembers/Backend1.jpg';
import Backend2 from '../assets/images/TeamMembers/Backend2.jpg';
import Mobile1 from '../assets/images/TeamMembers/Mobile1.jpg';
import Mobile2 from '../assets/images/TeamMembers/Mobile2.jpg';
import UIUX1 from '../assets/images/TeamMembers/UIUX1.jpg';
import UIUX2 from '../assets/images/TeamMembers/UIUX2.jpg';

import { SiDart } from "react-icons/si";
import { FaBeer } from 'react-icons/fa'; // Import the icon
import { SiSwift } from "react-icons/si";
import { SiFlutter } from "react-icons/si";
import { FaReact } from "react-icons/fa";
import { FaNode } from "react-icons/fa6";
import { IoLogoAndroid } from "react-icons/io";
import { FaJava } from "react-icons/fa";

import { TbBrandDjango } from "react-icons/tb";

// Product Img Imports 
import KR2 from '../assets/images/project/kr2.png';
import KuppaiRajaSeller from '../assets/images/project/kr-1.png';
import KuppaiRaja from '../assets/images/project/kr3.png';

import Hropal1 from '../assets/images/project/hropal1.png';
import Hropal2 from '../assets/images/project/hropal2.jpeg';
import Hropal3 from '../assets/images/project/Hropal-3.png';

import Indine1 from '../assets/images/project/indine1.png';
import Indine2 from '../assets/images/project/indine2.png';
import Indine3 from '../assets/images/project/indine3.png';

import AstroHub1 from '../assets/images/project/astroHub.png';
import AstroHub2 from '../assets/images/project/astroHub2.png';
import AstroHub3 from '../assets/images/project/astroHub3.png';

import Smart1 from '../assets/images/project/smart1.png';
import Smart2 from '../assets/images/project/smart2.png';
import Smart3 from '../assets/images/project/smart3.png';

import Modenteo1 from '../assets/images/project/modenteo.png';
import Modenteo2 from '../assets/images/project/modenteo2.png';
import Modenteo3 from '../assets/images/project/modenteo4.png';


export const Products = [
      {
        id: 1,
        title: 'KuppaiRaja',
        title_desc: 'Waste Management Reimagined',
        imageUrl: KR2,
        long_description:
          'Kuppairaja revolutionizes waste management and delivers an unparalleled user experience, allowing you to request scrap pickups with a mere swipe. From outdated newspapers to electronic waste, Kuppairaja handles it all with unparalleled efficiency. Embracing Kuppairaja means stepping into the vanguard of the green revolution, making you a pivotal force in this transformative movement. With Kuppairaja, waste management becomes not just a task, but a powerful statement towards a greener, more sustainable world.',
        short_description: 'Kuppairaja is our smart scrap collection app, turning waste into value with just a click.',
        images: [
          {
            id: 1,
            title: 'Effortless Scrap Pickup',
            imageUrl: KR2,
            description: 'Kuppairaja allows you to request scrap pickups seamlessly, making recycling just a tap away.',
          },
          {
            id: 2,
            title: 'Connect and Recycle',
            imageUrl: KuppaiRajaSeller,
            description: 'Kuppairaja links you with local recyclers, simplifying sustainability and making a real impact.',
          },
          {
            id: 3,
            title: 'Lead the Green Revolution',
            imageUrl: KuppaiRaja,
            description: 'Join Kuppairaja’s smart collection services and be part of the movement towards a sustainable future.',
          },
        ],
        tech: [
          { id: 1, name: 'Dart', icon: SiDart },
          { id: 2, name: 'Flutter', icon: SiFlutter },
        ],
        animation: 'right',
      },
      {
        id: 2,
        title: 'Hropal',
        title_desc: 'HR Efficiency Redefined',
        imageUrl: Hropal1,
        long_description:
          'Hropal is setting a new standard in HR management with its trailblazing software, destined to revolutionize workforce administration. This state-of-the-art platform delivers a paradigm shift in HR efficiency, allowing you to command your HR functions with a single, effortless tap. Step into the future of HR excellence with Hropal and experience unparalleled success and efficiency in managing your most valuable asset—your team.',
        short_description: 'Hropal is our smart HR software, revolutionizing workforce management with just a tap.',
        images: [
          {
            id: 1,
            title: 'Optimize Your HR Workflow ',
            imageUrl: Hropal1,
            description: 'Hropal is our advanced HR management software, streamlining your HR tasks for greater efficiency.',
          },
          {
            id: 2,
            title: 'Seamless Employee Management',
            imageUrl: Hropal2,
            description: 'With Hropal, effortlessly manage employee records, payroll, and performance—all in one place.',
          },
          {
            id: 3,
            title: 'Transform Your HR Experience',
            imageUrl: Hropal3,
            description: 'Elevate your HR operations with Hropal’s intuitive features and smart solutions for a more productive workplace.',
          },
        ],
        tech: [
          { id: 1, name: 'Dart', icon: SiDart },
          { id: 2, name: 'Flutter', icon: SiFlutter },
        ],
        animation: 'right',
      },
      {
        id: 3,
        title: 'Sagco Smart',
        title_desc: 'Precision In Every Bottle',
        imageUrl: Smart1,
        long_description:
          'Sagco Smart is revolutionizing the realm of bottle management with its groundbreaking tracking solution, delivering a quantum leap in precision and efficiency. Harness the power of Sagco Smart’s innovative tracking technology and achieve flawless bottle management that sets a new standard in operational excellence. Embrace a future where precision meets efficiency with Sagco Smart, and transform how you manage your inventory.',
        short_description: 'Sagco Smart is our advanced tracking solution, optimizing bottle management with unrivaled accuracy.',
        images: [
          {
            id: 1,
            title: 'Revolutionize Bottle Tracking',
            imageUrl: Smart1,
            description: 'Sagco Smart is our state-of-the-art solution, enhancing bottle management with exceptional accuracy.',
          },
          {
            id: 2,
            title: 'Effortless Inventory Control',
            imageUrl: Smart2,
            description: 'Sagco Smart simplifies tracking and managing your bottles, making inventory a breeze.',
          },
          {
            id: 3,
            title: 'Track Your Bottles with Precision',
            imageUrl: Smart3,
            description: 'Experience flawless bottle management with Sagco Smart’s innovative tracking technology.',
          },
        ],
        tech: [
          { id: 1, name: 'Android', icon: IoLogoAndroid },
          { id: 2, name: 'Java', icon: FaJava },
        ],
        animation: 'right',
      },
      {
        id: 4,
        title: 'Indine',
        title_desc: 'Perfection In Every Plate',
        imageUrl: Indine1,
        long_description:
          'Indine is revolutionizing the world of culinary and event experiences with its groundbreaking app, setting an unparalleled standard of excellence.Whether it’s a sumptuous feast or a grand gathering, Indine ensures every detail is executed with precision and elegance from a single, seamless interface. Revel in the ultimate convenience and sophistication with Indine, where every event and meal is flawlessly orchestrated to create a truly remarkable experience',
        short_description: 'Indine is your all-in-one app for delectable deliveries and flawless event planning, making every occasion unforgettable.',
        images: [
          {
            id: 1,
            title: 'Delicious Events Perfected',
            imageUrl: Indine1,
            description: 'Indine combines seamless food delivery with top-notch event management, all in one app.',
          },
          {
            id: 2,
            title: 'Simplify Your Celebrations',
            imageUrl: Indine2,
            description: 'Effortlessly order delicious meals and manage events with Indine’s user-friendly platform.',
          },
          {
            id: 3,
            title: 'Delight in Every Detail',
            imageUrl: Indine3,
            description: 'From food to festivities, Indine handles it all with precision and ease.',
          },
        ],
        tech: [
          { id: 1, name: 'Dart', icon: SiDart },
          { id: 2, name: 'Flutter', icon: SiFlutter },
        ],
        animation: 'left',
      },
      {
        id: 5,
        title: 'AstroHub',
        title_desc: 'Cosmic Clarity Anytime',
        imageUrl: AstroHub1,
        long_description:
          'AstroHub is unleashing a celestial revolution with its extraordinary platform, bringing cosmic clarity to your fingertips at any hour. This groundbreaking app catapults you into a universe of astrological brilliance, connecting you with elite astrologers 24/7 for instant, divine insights and prophetic guidance. Immerse yourself in a cosmic odyssey where every query is met with tailored, transcendent advice, delivered straight to your device. Embrace the future where every moment is illuminated by the stars and your path is guided by the universe itself.',
        short_description:
          'AstroHub connects you with expert astrologers around the clock, providing instant clarity and cosmic insights whenever you need them.',
        images: [
          {
            id: 1,
            title: 'Connect with Experts Anytime',
            imageUrl: AstroHub1,
            description: 'AstroHub offers 24/7 access to experienced astrologers for real-time consultations.',
          },
          {
            id: 2,
            title: 'Get Clarity on Your Future',
            imageUrl: AstroHub2,
            description: 'Easily resolve your astrological questions and gain insights into your life’s path.',
          },
          {
            id: 3,
            title: 'Personalized Guidance at Your Fingertips',
            imageUrl: AstroHub3,
            description: 'Receive tailored astrological advice and guidance, right from your device.',
          },
        ],
        tech: [
          { id: 1, name: 'Dart', icon: SiDart },
          { id: 2, name: 'Flutter', icon: SiFlutter },
        ],
        animation: 'up',
      },
      {
        id: 6,
        title: 'Modenteo',
        title_desc: ' Fashion Made Simple',
        imageUrl: Modenteo2,
        long_description:
          "Modenteo redefines online shopping, bringing the latest fashion trends to your fingertips with a curated collection that caters to every style. Whether you're looking for chic casuals, elegant formals, or the latest athleisure, Modenteo offers a seamless shopping experience that’s fast, fun, and personalized. With Modenteo, your style journey isn’t just a click—it’s a statement!",
        short_description:
          'Modenteo is your ultimate fashion destination, bringing the latest styles to your doorstep with just a click.',
        images: [
          {
            id: 1,
            title: 'Effortless Shopping',
            imageUrl: Modenteo1,
            description: 'Modenteo makes finding your perfect outfit a breeze, offering a seamless shopping experience tailored to your taste.',
          },
          {
            id: 2,
            title: 'Style at Your Fingertips',
            imageUrl: Modenteo2,
            description: 'Explore a curated collection of trendy apparel that keeps you ahead of the fashion curve, all from the comfort of your home.',
          },
          {
            id: 3,
            title: 'Elevate Your Wardrobe',
            imageUrl: Modenteo3,
            description: 'Join the Modenteo movement and transform the way you shop—because style should be as easy as a tap.',
          },
        ],
        tech: [
          { id: 1, name: 'React', icon: FaReact },
          { id: 2, name: 'Node', icon: FaNode },
        ],
        animation: 'up',
      },
    ];



// job posting
export const Jobs = [
  {
    "id": 1,
    "title": "Flutter Developer",
    "experience": "0-2 Years",
    "location": "Cuddalore",
    "description": [
      "Experience with mobile application development tools is a plus.",
      "Strong background in Dart, Flutter, and Firebase",
      "Experience in writing programs in mobile app frameworks and a deep understanding of its architecture.",
      "Strong understanding of API development and integration within mobile applications."
    ],
    "animation": "left"
  },
  {
    "id": 2,
    "title": "React.js Developer",
    "experience": "0-2 Years",
    "location": "Cuddalore",
    "description": [
      "Experience with modern front-end development tools and frameworks is a plus.",
      "Strong background in JavaScript, React.js, & Node.js",
      "Experience in building and optimizing React applications with a deep understanding of component architecture and state management.",
      "Strong understanding of API development and integration with React front-ends."
    ],
    "animation": "left"
  },
  {
    "id": 3,
    "title": "Node.js Developer",
    "experience": "0-2 Years",
    "location": "Cuddalore",
    "description": [
      "Experience with backend development tools and frameworks is a plus.",
      "Strong background in JavaScript, Node.js, & Express.js.",
      "Experience in building and optimizing server-side applications with a deep understanding of asynchronous programming and event-driven architecture.",
      "Strong understanding of API development and integration with databases and front-end applications."
    ],
    "animation": "left"
  },
  {
    "id": 4,
    "title": "Zoho Creator",
    "experience": "0-2 Years",
    "location": "Cuddalore",
    "description": [
      "Experience with low-code development platforms and tools is a plus.",
      "Strong background in Zoho Creator, Deluge scripting, & Zoho ecosystem integration.",
      "Experience in building custom applications with a deep understanding of workflows, automation, and data modeling.",
      "Strong understanding of REST/JSON-based API development and integration with third-party services and Zoho apps."
    ],
    "animation": "left"
  }
];

// intern ship
export const Internships = [
  {
    "id": 1,
    "title": "UI/UX Internship",
    "duration": "15 Days ",
    "class_mode": "Online, Offline",
    "class_hour": "2 Hours",
    "location": "Cuddalore",
    "description": [
      "Duration : 15 Days",
      "Class Mode : Online, Offline",
      "Class Hour : 2 Hours",
      "Location : Cuddalore",
    ],
    "animation": "left"
  },

  {
    "id": 2,
    "title": "Digital Marketing",
    "duration": "3 Months",
    "class_mode": "Online, Offline",
    "class_hour": "2 Hours",
    "location": "Cuddalore",
    "description": [
      "Duration : 3 Months",
      "Class Mode : Online, Offline",
      "Class Hour : 2 Hours",
      "Location : Cuddalore",
    ],
    "animation": "left"
  },
  {
    "id": 3,
    "title": "Web Development",
    "duration": "3 Months",
    "class_mode": "Online, Offline",
    "class_hour": "2 Hours",
    "location": "Cuddalore",
    "description": [
      "Duration : 3 Months",
      "Class Mode : Online, Offline",
      "Class Hour : 2 Hours",
      "Location : Cuddalore",
    ],
    "animation": "left"
  },
  {
    "id": 4,
    "title": "iOS Development",
    "duration": "3 Months",
    "class_mode": "Online, Offline",
    "class_hour": "2 Hours",
    "location": "Cuddalore",
    "description": [
      "Duration : 3 Months",
      "Class Mode : Online, Offline",
      "Class Hour : 2 Hours",
      "Location : Cuddalore",
    ],
    "animation": "left"
  },
  {
    "id": 5,
    "title": "Full Stack Development",
    "duration": "3 Months",
    "class_mode": "Online, Offline",
    "class_hour": "2 Hours",
    "location": "Cuddalore",
    "description": [
      "Duration : 3 Months",
      "Class Mode : Online, Offline",
      "Class Hour : 2 Hours",
      "Location : Cuddalore",
    ],
    "animation": "left"
  },
  {
    "id": 6,
    "title": "Android Internship",
    "duration": "3 Months",
    "class_mode": "Online, Offline",
    "class_hour": "2 Hours",
    "location": "Cuddalore",
    "description": [
      "Duration : 3 Months",
      "Class Mode : Online, Offline",
      "Class Hour : 2 Hours",
      "Location : Cuddalore",
    ],
    "animation": "left"
  },
  {
    "id": 7,
    "title": "Data Science & Machine Learning",
    "duration": "5 Months",
    "class_mode": "Online, Offline",
    "class_hour": "2 Hours",
    "location": "Cuddalore",
    "description": [
      "Duration : 5 Months",
      "Class Mode : Online, Offline",
      "Class Hour : 2 Hours",
      "Location : Cuddalore",
    ],
    "animation": "left"
  },

];




export const Services = [
  { 
    "id": 1,
    "title": "Web Development",
    "imageUrl": Web,
    "animation": 'left',
    "detail_short_title": "Decoding",
    "detail_title": "Web Development",
    "detailImageUrl": WebDetail,
    "tech": [
            { id: 1, name: 'Django', icon: 'SiDjango' }, 
            { id: 2, name: 'NodeJs', icon: 'SiNodedotjs' }, 
            { id: 3, name: 'React', icon: 'SiReact' }, 
            { id: 4, name: 'Laravel', icon: 'SiLaravel' }, 
            { id: 5, name: 'Bootstrap', icon: 'SiBootstrap' }, 
            { id: 6, name: 'Tailwindcss', icon: 'SiTailwindcss' }, 
            { id: 7, name: 'Javascript', icon: 'SiJavascript' }, 
            { id: 8, name: 'CSS', icon: 'SiCss3' }, 
            { id: 9, name: 'HTML', icon: 'SiHtml5' }, 
            { id: 10, name: 'Wordpress', icon: 'SiWordpress' },
            { id: 11, name: 'Php', icon: 'SiPhp' }, 
            { id: 12, name: 'Flask', icon: 'SiFlask' },  

        ],
    "detail_description":"Your business is like a bustling city, and your website serves as its iconic skyscraper—the landmark that everyone wants to visit. Web development goes beyond just creating websites; it's about building a digital experience that aligns with your business objectives, engages users, and stands the test of time. Think of web development as the art of constructing that skyscraper brick-by-brick. It encompasses everything from laying the foundation with HTML, adding stunning exteriors using CSS, to ensuring that every interactive element, like elevators and lights,works flawlessly through JavaScript. Your website becomes your brand’s digital headquarters—open 24/7 for customers to explore, engage, and connect!",
    "services": [
      {
      "id":1,
      "short_title":"Your trusted partner",
      "title":"Scode’s Competitive Edge",
      "description":"At Scode Software Solutions, we build more than just websites — we create digital masterpieces. From robust e-commerce platforms to dynamic portals, our web development team fuses aesthetics with functionality, ensuring every pixel is perfect and every line of code serves a purpose. Whether it's crafting jaw-dropping front-end designs or architecting seamless back-end systems or you need a sleek portfolio, or an interactive web application, our expert team is dedicated to crafting solutions that are visually stunning and seamlessly functional.",
      "service": [
        {
          "id":1,
          "title":"Accessible Anywhere",
          "description":"Websites can be viewed on any device with internet, so users can access your services anytime, anywhere.",

        },
        {
          "id":2,
          "title":"Easy to Find",
          "description":"A well-made website helps your business appear on search engines like Google, making it easier for customers to find you.",

        },
        {
          "id":3,
          "title":"Simple Updates",
          "description":"You can change website content easily, keeping information current without needing users to download anything",

        },
        {
          "id":4,
          "title":"Track Visitor Behavior",
          "description":"Websites can collect data on how visitors interact, helping improve the user experience over time.",

        }
      ]

      }

    ],

  },
  {
    "id": 2,
    "title": "Android Development",
    "imageUrl": Android,
    "animation": "up",
    "detail_short_title": "Breaking down",
    "detail_title": "Android Development",
    "detailImageUrl": AndroidDetail,
    "tech": [
            { id: 1, name: 'Android', icon: 'TbBrandAndroid' }, 
            { id: 2, name: 'Java', icon: 'FaJava' }, 
            { id: 3, name: 'Kotlin', icon: 'SiKotlin' }, 
            { id: 4, name: 'Androidstudio', icon: 'SiAndroidstudio' }, 
            { id: 5, name: 'Xml', icon: 'TbFileTypeXml' }, 
            

        ],
    "detail_description":"Ever wanted to put your app idea in the hands of millions? Android Development is your ticket to making that dream a reality! With its friendly green robot, Android rules the mobile world, giving developers a playground to create apps that educate, entertain, and enchant. From productivity boosters to addictive games, Android apps can turn any phone into a personal assistant, a pocket arcade, or even a digital stage for your creativity.",
    "services": [
      {
      "id":1,
      "short_title":"Your trusted partner",
      "title":"Scode’s Competitive Edge",
      "description":"Unlock the potential of the Android universe with apps that don’t just function, but leave a mark! Our Android development services go beyond the basics — we specialize in creating apps that redefine user experiences, merging intuitive design with groundbreaking features. From immersive gaming apps to productivity-boosting solutions, we engineer Android apps that work flawlessly on every device and every version, ensuring your brand reaches millions with a tap. Get ready to dominate the Google Play Store!",
      "service": [
        {
          "id":1,
          "title":"Large Audience",
          "description":"Android is used by millions, giving your app the chance to reach many users.",

        },
        {
          "id":2,
          "title":"Flexible Customization",
          "description":"You can create unique features for your app that cater to specific user needs.",

        },
        {
          "id":3,
          "title":"Free to Use",
          "description":"Android is open-source, allowing developers to collaborate and innovate freely.",

        },
        {
          "id":5,
          "title":"Use Device Features",
          "description":"Android apps can easily access features like GPS and cameras, making them more functional.",

        },
    
        
      ]

      }

    ]
  },
  {
    "id": 3,
    "title": "iOS Development",
    "imageUrl": IOS,
    "animation": "right",
    "detail_short_title": "Demystifying",
    "detail_title": "iOS Development",
    "detailImageUrl": IosDetail,
    "tech": [
            { id: 1, name: 'Swift', icon: 'FaSwift' }, 
            { id: 2, name: 'Xcode', icon: 'SiXcode' }, 
            { id: 3, name: 'Xml', icon: 'TbFileTypeXml' }, 
            { id: 4, name: 'Swift UI', icon: 'TbBrandSwift' }, 
            { id: 5, name: 'Json', icon: 'TbJson' },


        ],
    "detail_description":"iOS Development is all about crafting a premium experience—designing the smoothest, sleekest,and most sophisticated digital product in the tech world. Whether it’s for an iPhone, iPad, or even the Apple Watch, building for iOS means delivering elegance paired with unmatched performance. Using Swift—a language that lives up to its name—iOS apps are designed to impress, delight, and captivate, ensuring every swipe and tap feels seamless and intuitive. It’s the gold standard of app development, where precision meets innovation, creating an experience that’s nothing short of magical.",
    "services": [
      {
      "id":1,
      "short_title":"Your trusted partner",
      "title":"Scode’s Competitive Edge",
      "description":"In the realm of Apple, only the extraordinary thrive. At SCODE, our developers are maestros in leveraging the iOS ecosystem’s full potential — from iPhones to iPads and beyond. With sleek designs, seamless navigation, and bulletproof security, our apps are engineered to impress, engage, and perform at the highest level. Whether you're looking to create the next chart-topping app or a cutting-edge enterprise solution, we blend creativity with code to deliver unparalleled digital experiences for the discerning Apple audience.Join us in creating the next iPhone sensation that will leave a lasting impression in the Apple ecosystem!",
      "service": [
        {
          "id":1,
          "title":"Premium User Experience",
          "description":"iOS users typically expect great apps, which can lead to higher satisfaction and loyalty.",

        },
        {
          "id":2,
          "title":"Strong Security",
          "description":"iOS is known for its security, which helps keep user data safe.",

        },
        {
          "id":3,
          "title":"Higher Revenue Potential",
          "description":"iOS users are often willing to pay for apps, increasing your chances of making money.",

        },
        {
          "id":4,
          "title":"Regular Updates",
          "description":"Apple provides consistent updates, ensuring your app stays compatible with new features.",

        },
        {
          "id":5,
          "title":"Less Fragmentation",
          "description":"With fewer iOS devices, it’s easier to develop and test your app effectively.",

        },
      ]

      }

    ]
  },
  {
    "id": 4,
    "title": "Backend/API Development",
    "imageUrl": Backend,
    "animation": "right",
    "detail_short_title": "Unraveling",
    "detail_title": "Backend And API Development",
    "detailImageUrl": BackendDetail,
    "tech": [
            { id: 1, name: 'Django', icon: 'SiDjango' }, 
            { id: 2, name: 'NodeJs', icon: 'SiNodedotjs' }, 
            { id: 3, name: 'Graphql', icon: 'SiGraphql' }, 
            { id: 4, name: 'Rest Api', icon: 'TbApi' }, 
            { id: 5, name: 'Fastapi', icon: 'SiFastapi' }, 
            { id: 6, name: 'Postgresql', icon: 'SiPostgresql' }, 
            { id: 7, name: 'Python', icon: 'FaPython' }, 
            { id: 8, name: 'Javascript', icon: 'SiJavascript' }, 
            { id: 9, name: 'Mysql', icon: 'SiMysql' },
            { id: 10, name: 'Json', icon: 'TbJson' }, 

        ],
    "detail_description":"Backend Development is like the powerhouse fueling your app’s digital city. It’s where all the heavy-duty operations happen—managing data, coordinating users, and ensuring that everything runs like a well-oiled machine behind the scenes. APIs, meanwhile, serve as the secret passageways connecting different parts of your digital ecosystem. Need your app to book a flight, post a tweet, or process payments? The backend and APIs are the masterminds making these complex interactions seamless and efficient—quiet, robust, and absolutely essential for your app’s functionality and success.",
    "services": [
      {
      "id":1,
      "short_title":"Your trusted partner",
      "title":"Scode’s Competitive Edge",
      "description":"The heart of every great application is its backend — the engine that drives data, security, and performance. Our backend and API development services are designed to provide the architectural brilliance your application needs to thrive. We build scalable, secure, and high-performing backend systems that seamlessly integrate with front-end interfaces, enabling smooth data flow and rock-solid functionality. With custom APIs that act as the digital glue binding your systems together, we ensure your software is as powerful on the inside as it is stunning on the outside.",
      "service": [
        {
          "id":1,
          "title":"Scalability",
          "description":"A good backend can support more users as your business expands, ensuring smooth operation.",

        },
        {
          "id":2,
          "title":"Manages Data",
          "description":"Backend systems keep track of user data and ensure it's easy to retrieve when needed.",

        },
        {
          "id":3,
          "title":"Interoperability",
          "description":"APIs allow your app to work with other software and services, enhancing functionality.",

        },
        {
          "id":4,
          "title":"Keeps Data Safe",
          "description":"A secure backend protects sensitive information from unauthorized access.",

        },
        {
          "id":5,
          "title":"Faster Development",
          "description":"APIs let developers use existing tools, saving time in building new features.",

        },
      ]

      }

    ]
  },
  {
    "id": 5,
    "title": "Hybrid Development",
    "imageUrl": Hybrid,
    "animation": "right",
    "detail_short_title": "Deciphering",
    "detail_title": "Hybrid App Development",
    "detailImageUrl": HybridDetail,
    "tech": [
            { id: 1, name: 'Dart', icon: 'SiDart' }, 
            { id: 2, name: 'Flutter', icon: 'SiFlutter' }, 
            { id: 3, name: 'ReactNative', icon: 'TbBrandReactNative' }, 
            { id: 4, name: 'JavaScript', icon: 'FaJs' }, 
            { id: 5, name: 'Json', icon: 'TbJson' }, 

        ],
    "detail_description":"Why settle for just Android or iOS when you can have the best of both worlds? Hybrid Development is your ultimate “two-in-one” solution—one codebase, two platforms, and double the reach! With cutting-edge frameworks like Flutter and React Native, hybrid apps seamlessly blend the strengths of web and mobile, offering a near-native experience without the hassle of separate development. It’s fast, cost-effective, and the ideal way to launch your idea to both Android and iOS users simultaneously, ensuring no audience is left behind.",
    "services": [
      {
      "id":1,
      "short_title":"Your trusted partner",
      "title":"Scode’s Competitive Edge",
      "description":"Why choose between platforms when you can dominate them all? Our Hybrid Development solutions offer the perfect blend of native performance and cross-platform compatibility, giving your users a consistent and high-quality experience on every device. From mobile to web, we use powerful frameworks to build apps that are fast, flexible, and flawless. Save time, reduce costs, and conquer multiple platforms without compromising on quality. It’s time to break boundaries and expand your reach with a single codebase that delivers an app experience like no other.",
      "service": [
        {
          "id":1,
          "title":"One App for All",
          "description":"A good backend can support more users as your business expands, ensuring smooth operation.",

        },
        {
          "id":2,
          "title":"Reaches More Users",
          "description":"By being available on multiple platforms, you can attract a wider audience.",

        },
        {
          "id":3,
          "title":"Faster to Launch",
          "description":"Hybrid apps can be developed and released more quickly, getting your product to market sooner.",

        },
        {
          "id":4,
          "title":"Access to Features",
          "description":"Hybrid apps can use device features just like native apps, improving user experience.",

        },
        {
          "id":5,
          "title":"Easy to Update",
          "description":"Changes can be made in one place and applied to all platforms, simplifying maintenance.",

        },
      ]

      }

    ]
  },
  {
    "id": 6,
    "title": "UI/UX Design",
    "imageUrl": UIUX,
    "animation": "right",
    "detail_short_title": "Revealing",
    "detail_title": "UI/UX Design",
    "detailImageUrl": UIUXDetail,
    "tech": [
            { id: 1, name: 'Figma', icon: 'FaFigma' }, 
            { id: 2, name: 'Adobe', icon: 'SiAdobe' }, 
            { id: 3, name: 'Canva', icon: 'SiCanva' }, 
            { id: 4, name: 'Photoshop', icon: 'SiAdobephotoshop' }, 
            { id: 5, name: 'Adobexd', icon: 'SiAdobexd' }, 
            { id: 6, name: 'Framer', icon: 'SiFramer' }

        ],
    "detail_description":"In today’s digital landscape, delivering an exceptional user experience is crucial for the success of any digital product. UI/UX Design is where art meets strategy, and aesthetics align with functionality. Think of an app as a grand event—UI (User Interface) is the visual décor: the vibrant colors, engaging layouts, and eye-catching elements that set the scene. UX (User Experience) is the atmosphere: ensuring everything flows seamlessly, guests feel welcomed, and navigation is intuitive. When combined, UI and UX create a digital experience that’s captivating and memorable, making your app the centerpiece everyone talks about long after the event ends",
    "services": [
      {
      "id":1,
      "short_title":"Your trusted partner",
      "title":"Scode’s Competitive Edge",
      "description":"Every click, every swipe, every interaction matters. At SCODE, we don’t just design interfaces — we craft experiences. Our UI/UX design team dives deep into user psychology to create stunning visuals and intuitive layouts that resonate with users. From wireframes to final prototypes, we transform complex concepts into sleek, user-friendly designs that are not only visually compelling but also incredibly functional. Make your users fall in love with interfaces that blend beauty with usability, leaving a lasting impression at every touchpoint.",
      "service": [
        {
          "id":1,
          "title":"User-Friendly",
          "description":"Good design makes apps and websites easy to use, keeping users happy and engaged.",

        },
        {
          "id":2,
          "title":"Increased Engagement",
          "description":"Attractive and intuitive design prompts users to explore more of your app or site.",

        },
        {
          "id":3,
          "title":"Competitive Advantage",
          "description":"Unique designs can help your business get noticed among competitors.",

        },
        {
          "id":4,
          "title":"Reduces Development Cost",
          "description":"Investing in design upfront can prevent costly changes after launch.",

        },
        {
          "id":5,
          "title":"Inclusive for All",
          "description":"Thoughtful design considers different user needs, ensuring everyone can use your app or website.",

        },
      ]

      }

    ]
  },
  {
    "id": 7,
    "title": "Digital Marketing",
    "imageUrl": DigitalMarketing,
    "animation": "right",
    "detail_short_title": "Clarifying",
    "detail_title": "Digital Marketing",
    "detailImageUrl": DMDetail,
    "tech": [
      { id: 1, name: 'Gmail marketing', icon: 'SiGmail' }, 
      { id: 2, name: 'Social media marketing', icon: 'FaCameraRetro' }, 
      { id: 3, name: 'Content marketing', icon: 'TbNotes' }, 
      { id: 4, name: 'Mobile marketing', icon: 'FaMobileAlt' }, 
      { id: 5, name: 'Search Engine Optimization', icon: 'TbSeo' }, 
     
    ],
    "detail_description":"Digital Marketing is your brand’s megaphone, amplifying your voice for the world to hear. It’s about taking center stage on social media, search engines, and email platforms like a rockstar owning the spotlight—grabbing attention and keeping audiences captivated. With the power of SEO, engaging content, and viral campaigns, it’s a high-energy performance that turns clicks into connections and leads into loyal customers. Ready to transform your brand from a subtle whisper to a powerful roar? Digital marketing is the spotlight that ensures your business shines bright and commands attention.",
    "services": [
      {
      "id":1,
      "short_title":"Your trusted partner",
      "title":"Scode’s Competitive Edge",
      "description":"In today’s highly competitive digital landscape, having a robust online presence is crucial for any business. Whether you’re looking to improve your search visibility, engage on social media, or optimize your website for better conversions, we have the expertise to transform your digital footprint. Our digital marketing services are crafted to catapult your brand into the limelight, transforming clicks into loyal customers and browsers into buyers. It’s time to stop following trends and start setting them — with digital marketing that redefines success.",
      "service": [
        {
          "id":1,
          "title":"Target the Right People",
          "description":"Digital marketing lets you reach specific groups of people who are likely to be interested in your products.",

        },
        {
          "id":2,
          "title":"See Results Instantly",
          "description":"You can track how well your marketing is doing in real-time, allowing for quick adjustments.",

        },
        {
          "id":3,
          "title":"Cost-Effective",
          "description":"Digital marketing is often cheaper and can provide a better return on investment compared to traditional ads.",

        },
        {
          "id":4,
          "title":"Reach a Global Audience",
          "description":"You can connect with customers from around the world through online channels.",

        },
        {
          "id":5,
          "title":"Engagement Opportunities",
          "description":"Fun content (like quizzes or polls) encourages users to interact with your brand, building a community.",

        },
      ]

      }

    ]
  },
  {
    "id": 8,
    "title": "Internship Programs",
    "imageUrl": Internship,
    "animation": "right",
    "detail_short_title": "Unpacking",
    "detail_title": "Internship Programs",
    "detailImageUrl": InternShipDetail,
    "tech": [
      { 
        id: 1, 
        name: '“Scode Software Teach us Best Designing and Program Practical Skills. Teach How to Arrange Your Program Skills Professionally. Staff are Open Minded and make a Friendly Environment. Come and Try for Your Future Steps..” —Software Development Intern',
        icon: 'VscFeedback'
       },
       { 
        id: 2, 
        name: "“It's a wonderful experience for me . I attended UX and UI design for my internship.... I really want to learn to create an app page. I want to learn more about this ..... Thank you scode software solutions team and members for taking care and teaching well.”sss Software Teach us Best Designing and Program Practical Skills. Teach How to Arrange Your Program Skills Professionally. Staff are Open Minded and make a Friendly Environment. Come and Try for Your Future Steps..” —Software Development Intern",
        icon: 'VscFeedback'
       },
       { 
        id: 3, 
        name: "“One of the best places to grow our knowledge where people can experience the latest technologies & Collaborative work environment.”  —Full Stack Development Intern",
        icon: 'VscFeedback'
       },


    ],
    "detail_description":"Are you ready to embark on your journey from student to tech trailblazer? Our Internship Programs provide more than just experience—they serve as a launchpad for future innovators. Think of an internship as a sneak peek into the dynamic world of business—a backstage pass for young talent to gain hands-on experience and unleash their potential. For companies, it’s an opportunity to harness fresh perspectives and cultivate the next generation of leaders. Interns will dive into real projects, learn from industry experts, and hone their skills, setting the stage for a successful career in tech!",
    "services": [
      {
      "id":1,
      "short_title":"Your trusted partner",
      "title":"Scode’s Competitive Edge",
      "description":"Dive into real-world projects, learn from the best in the industry, and gain the skills that’ll set you apart in the job market. At SCODE, interns don’t just observe; they lead, create, and transform. Whether you’re a budding developer, a creative designer, or a marketing enthusiast, our internships are built to fuel your ambition and shape your career. Join us and transform your potential into performance — your future starts here!",
      "service": [
        {
          "id":1,
          "title":"Develop New Talent",
          "description":"Internships help train young people, bringing fresh ideas and energy to your business.",

        },
        {
          "id":2,
          "title":"Spread the Word",
          "description":"Happy interns can share their positive experiences, promoting your company to their friends and networks.",

        },
        {
          "id":3,
          "title":"Real-World Experience",
          "description":"Interns gain practical skills that prepare them for future jobs, benefiting both them and your industry.",

        },
        {
          "id":4,
          "title":"Diverse Ideas",
          "description":"Young interns often bring new perspectives that can lead to creative solutions for your projects.",

        },
        {
          "id":5,
          "title":"Future Hiring Opportunities",
          "description":"Internships allow you to evaluate potential employees before hiring them full-time.",

        },
      ]

      }

    ]
  },
];


export const ChooseReason = [
  [
    {
      id:1,
      title: 'Visionary Leadership',
      description: 'Led by a first-generation entrepreneur dedicated to decentralizing the tech industry.',
      imageUrl: vision,
    },
    {
      id:2,
      title: 'Pioneering Expertise',
      description: 'First to develop native iOS applications in Cuddalore.',
      imageUrl: expertise,
    },

  ],
  [
    {
      id:3,
      title: 'Community-Focused',
      description: 'Committed to empowering tier-3 cities by creating high-quality IT jobs locally.',
      imageUrl: community,
    },
    { 
      id:4,
      title: 'Talent-Driven',
      description: 'We prioritize skills and potential.',
      imageUrl: talent,
    },

  ],
  [
    {
      id:5,
      title: 'Proven Track Record',
      description: 'A growing portfolio of successful projects.',
      imageUrl: track,
    },
    {
      id:6,
      title: 'Unmatched Quality',
      description: 'Deliver reliable, high-quality software solutions.',
      imageUrl: quality,
    },

  ],
]


export const Testimonials = [
  {
    id: 1,
    name: 'Sasha Rose',
    company: 'Owner, Surveyor Corps',
    testimoni: 'Thanks for Racxstudio, you guys are the best! Keep up the great work!',
    imageUrl: Sasha,
  },
  {
    id: 2,
    name: 'Kruger Khan',
    company: 'Director, Shultan Oil',
    testimoni: 'I just wanted to let you know that it’s been great working with Racxstudio.',
    imageUrl: Kruger,
  },
  {
    id: 3,
    name: 'Reiner John',
    company: 'CEO, Marley CO',
    testimoni: 'Racxstudio is so great. Thank you so much for a job well done.',
    imageUrl: Reiner,
  },
];



export const CurrentProjects = [
  {
    id: 1,
    name: 'AstroHub',
    company: 'Owner, Surveyor Corps',
    testimoni: "AstroHub is our latest venture at SCODE Software Solutions, designed as a comprehensive platform for astrology enthusiasts. Whether you're seeking daily horoscopes, personalized astrological insights, or guidance on life's journey, AstroHub delivers accurate and meaningful content tailored to your needs. Our goal is to make astrology accessible and insightful, helping users make informed decisions and find clarity in their lives.",
    imageUrl: AstroHub,
    logoImageUrl: AstroHubLogo,
    tech: [
      { id: 1, name: 'Dart', icon: SiDart },
      { id: 2, name: 'Flutter', icon: SiFlutter },
    ]
  },
  {
    id: 2,
    name: 'Delivery SG',
    company: 'Director, Shultan Oil',
    testimoni: 'Delivery SG is our innovative food delivery platform, designed to bring your favorite meals right to your doorstep. Delivery SG connects you with a wide range of local restaurants and eateries, offering a seamless and efficient way to satisfy your cravings. With user-friendly features and real-time tracking, our app ensures that your food is delivered hot, fresh, and on time, making every meal a delightful experience.',
    imageUrl: DeliverySG,
    logoImageUrl: DeliverySGLogo,
    tech: [
      { id: 1, name: 'Swift', icon: SiSwift },
    ]
  },
  {
    id: 3,
    name: 'iPoll',
    company: 'CEO, Marley CO',
    testimoni: "iPoll is a unique Q&A platform designed exclusively for polling. iPoll focuses on the power of community-driven decisions through polls. Users can easily post questions and receive instant feedback from a diverse audience. Whether you're curious about popular opinions or need quick insights, iPoll delivers real-time results in a simple, engaging format. With a constantly updated list of random questions, iPoll keeps the conversation going, making every vote count.",
    imageUrl: iPoll,
    logoImageUrl: iPollLogo,
    tech: [
      { id: 1, name: 'Dart', icon: SiDart },
      { id: 2, name: 'Flutter', icon: SiFlutter },
    ]
  },
];


export const TeamMembers = [
  {
    name: 'Rach David',
    position: 'CEO',
    imageUrl: CEO,
  },
  {
    name: 'Pauline Sydney',
    position: 'HRD',
    imageUrl: HRD,
  },
  {
    name: 'Granger Watterson',
    position: 'Finance',
    imageUrl: Finance,
  },
  {
    name: 'Tom Jimmy',
    position: 'Project Manager',
    imageUrl: ProjectManager,
  },
  {
    name: 'Jim Hendrix',
    position: 'Front-end Developer',
    imageUrl: Frontend1,
  },
  {
    name: 'Calvin Max',
    position: 'Front-end Developer',
    imageUrl: Frontend2,
  },
  {
    name: 'Hawkins Jim',
    position: 'Back-end Developer',
    imageUrl: Backend1,
  },
  {
    name: 'Don Bizaro',
    position: 'Back-end Developer',
    imageUrl: Backend2,
  },
  {
    name: 'Bill Markinson',
    position: 'Mobile Developer',
    imageUrl: Mobile1,
  },
  {
    name: 'Igor Kavarov',
    position: 'Mobile Developer',
    imageUrl: Mobile2,
  },
  {
    name: 'Freddie Curl',
    position: 'UI/UX Designer',
    imageUrl: UIUX2,
  },
  {
    name: 'Monica Lovegood',
    position: 'UI/UX Designer',
    imageUrl: UIUX1,
  },
];


export const blogPosts = [

  // {
  //   id:1,
  //   title: 'The Future of Artificial Intelligence: Trends and Challenges',
  //   created_at: '2023-10-11',
  //   author: 'John',
  //   authorImage: 'https://randomuser.me/api/portraits/men/2.jpg',
  //   image:
  //     'https://images.unsplash.com/photo-1677442135703-1787eea5ce01?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHw1fHxhaXxlbnwwfDB8fHwxNzEyNzUzMTQ4fDA&ixlib=rb-4.0.3&q=80&w=1080',

  // },
  // {
  //   id:2,
  //   title: 'The Rise of Blockchain Technology: A Comprehensive Guide',
  //   created_at: '2023-09-25',
  //   author: 'Jane',
  //   authorImage: 'https://randomuser.me/api/portraits/women/2.jpg',
  //   image:
  //     'https://images.unsplash.com/photo-1639322537228-f710d846310a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHw1fHxibG9jayUyMGNoYWlufGVufDB8MHx8fDE3MTI3NTMxNjd8MA&ixlib=rb-4.0.3&q=80&w=1080',
  
  // },
  // { id:3,
  //   title: 'How Quantum Computing Will Revolutionize Data Security',
  //   created_at: '2023-09-24',
  //   author: 'Michael',
  //   authorImage: 'https://randomuser.me/api/portraits/men/4.jpg',
  //   image:
  //     'https://images.unsplash.com/photo-1666112835156-c65bb806ac73?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHwxNXx8cXVhbnR1bSUyMGNvbXB1dGluZ3xlbnwwfDB8fHwxNzEyNzUzMTk2fDA&ixlib=rb-4.0.3&q=80&w=1080',
  // },
];



