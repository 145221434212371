import React from "react";
import { Fade } from "react-awesome-reveal";
import Button from "../elements/Button";
import BuildWebsite from "../assets/images/hero/hero.png";

export default function Hero() {
  return (
    
    <section className="hero flex flex-col lg:flex-row items-center justify-between ">
      <div className=" w-full lg:w-1/2 lg:pl-12 sm:pr-2  mx-auto">
      
        <h1 className="text-5xl lg:text-6xl sm:text-5xl text-theme-blue font-bold leading-tight mb-5">
          Precision Is Not a Goal; It's Our Promise
        </h1>

        <p className="font-light text-xl text-gray-400 leading-relaxed mb-10">
        Solving complex app codes that others give up <br /> on—that's our specialty.
        </p>

        
        <Fade direction="up" delay={50} triggerOnce>
          <Button
            href="/product"
            type="link"
            className="relative overflow-hidden flex h-12 items-center px-5 py-2 text-white text-xl bg-theme-purple rounded-lg shadow-2xl hover:bg-dark-theme-purple transition duration-200"
            style={{ width: "210px" }}
          >
            <span className="relative z-10">See Our Work</span>
            <div className="liquid"></div>
            <svg
              className="ml-2 w-7 h-7 text-white animate-bounce-x"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </Button>
        </Fade>
        
        {/* <Fade direction="up" delay={500} triggerOnce>
          <Button
            href="/product"
            type="link"
            className="flex h-12 items-center px-5 py-2 text-white text-xl bg-theme-purple rounded-lg shadow-2xl hover:bg-dark-theme-purple transition duration-200"
            style={{ width: '210px' }}
          >
            See Our Work
            <svg
              className="ml-2 w-7 h-7 text-white animate-bounce-x"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </Button>
        </Fade> */}
      </div>
      <div className="flex pt-6 lg:pt-2 md:w-fit w-full justify-center items-center lg:w-1/2">
        <Fade direction="up" triggerOnce>
          <img className="w-full object-cover" src={BuildWebsite} alt="Build Website" />
        </Fade>
      </div>
    </section>
  );
}
