
import React, { Component } from 'react';

import Header from 'parts/Header';
import Footer from 'parts/Footer';

import InternshipDetail from 'parts/InternshipDetail';


export default class InternshipApplyPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Header />
        <InternshipDetail />
        <Footer />
      </>
    );
  }
}
