import BlogDetail from 'parts/BlogDetail'
import Footer from 'parts/Footer'
import Header from 'parts/Header'
import React from 'react'

const BlogDetailPage = () => {
  return (
    <>
     <Header/>
     <BlogDetail />

     <Footer />
    </>
  )
}

export default BlogDetailPage