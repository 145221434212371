
import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { FaSuitcase } from 'react-icons/fa';
import { FaLocationDot } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

export default function JobPost({ data }) {
  const navigate = useNavigate();

  const handleApplyClick = (job) => {
    navigate(`/apply/${job.id}`, { state: job });
  };

  return (
    <div className="">
      <div className="container mx-auto p-5 lg:p-14">
        <Fade direction="right" triggerOnce>
          <h1 className="text-4xl text-theme-blue lg:text-center font-bold">Join Our Journey!</h1>
        </Fade>

        <Fade direction="left" triggerOnce>
          <p className="font-light text-lg text-gray-400 lg:text-center mb-5">
           We’re excited to welcome you aboard!  
          </p>
        </Fade>

        <div className="grid grid-rows-3 lg:px-2 gap-8 sm:grid-cols-3 sm:grid-rows-1 sm:gap-6 xl:gap-16">
          {data.map((item, index) => (
            <Fade direction={item.animation} delay={500 * index} key={item.id} triggerOnce>
              <div>
                <div className="bg-white group rounded-2xl shadow-2xl border transform transition duration-500 hover:scale-105 cursor-pointer p-6">
                  <h2 className="text-theme-blue text-xl py-2 rounded-b-2xl mb-4">{item.title}</h2>
                  <div className="text-left">
                    <div className="flex items-center mb-2">
                      <FaSuitcase className="mr-2 text-theme-blue" />
                      <p className="text-gray-600">Experience: {item.experience}</p>
                    </div>
                    <div className="flex items-center mb-4">
                      <FaLocationDot className="mr-2 text-theme-blue" />
                      <p className="text-gray-600">{item.location}</p>
                    </div>
                    <button
                      className="bg-theme-purple text-white px-4 py-2 rounded-full transition duration-300"
                      onClick={() => handleApplyClick(item)}
                    >
                      Apply Now
                    </button>
                  </div>
                </div>
              </div>
            </Fade>
          ))}
        </div>
      </div>
    </div>
  );
}











// import React from 'react';

// const JobPost = () => {
//   return (
//     <div className="relative flex flex-col items-center justify-center overflow-hidden bg-gray-50  sm:py-12">
//       <div className="bg-white shadow-xl shadow-gray-100 w-full max-w-4xl flex flex-col sm:flex-row gap-3 sm:items-center justify-between px-5 py-4 rounded-md">
//         <div>
//           <span className="text-purple-800 text-sm">Engineering</span>
//           <h3 className="font-bold mt-px">Senior Full Stack Backend Engineer</h3>
//           <div className="flex items-center gap-3 mt-2">
//             <span className="bg-purple-100 text-purple-700 rounded-full px-3 py-1 text-sm">Full-time</span>
//             <span className="text-slate-600 text-sm flex gap-1 items-center">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 className="h-4 w-4"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//                 strokeWidth="2"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
//                 />
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
//                 />
//               </svg>
//               Remote, UK
//             </span>
//           </div>
//         </div>
//         <div>
//           <button className="bg-purple-900 text-white font-medium px-4 py-2 rounded-md flex gap-1 items-center">
//             Apply Now
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               className="h-4 w-4"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//               strokeWidth="2"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 d="M13 7l5 5m0 0l-5 5m5-5H6"
//               />
//             </svg>
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default JobPost;
