

import React from 'react';

import { Fade } from 'react-awesome-reveal';
import { useNavigate } from 'react-router-dom';

export default function Service({ data }) {

  const navigate = useNavigate(); // Updated hook

  // Function to handle card click and navigate to detail page
  const handleCardClick = (item) => {
   
   const filteredItem = {
     ...item
   };
   
   navigate(`/services/${item.id}`,{ state: filteredItem }); // Updated navigation with filtered data
   
 };

  return (
    <div className="bg-gray-50">
      <div className="container mx-auto pt-20 lg:px-2 pb-28">
        <Fade direction="right" triggerOnce>
          <h1 className="text-4xl text-theme-blue text-center font-bold">Our Expertise</h1>
        </Fade>
        <Fade direction="left" triggerOnce>
          <p className="font-light text-lg text-gray-400 text-center mb-12">
            Unlock New Heights with Our Premium Service.
          </p>
        </Fade>

        <div className="grid grid-rows-3 px-4 lg:px-10 gap-8 sm:grid-cols-3 sm:grid-rows-1 sm:gap-6 xl:gap-16">
          {
            data.map((item, index) => (
              <Fade direction={item.animation} delay={50 * index} key={index} triggerOnce>
                <div>
                  <div className="bg-white group rounded-2xl shadow-2xl border  transform transition duration-500 hover:scale-105 cursor-pointer">
                    <img 
                      src={item.imageUrl} 
                      alt="Service" 
                      className=" rounded-t-lg"
                      onClick={() => handleCardClick(item)}
                      />
                    <h2 className="text-theme-blue text-center text-xl py-7 rounded-b-2xl">{item.title}</h2>
                  </div>
                </div>
              </Fade>

              
            ))
          }
        </div>
      </div>
    </div>
  );
}




// import React from 'react';

// import { Fade } from 'react-awesome-reveal';
// import { useNavigate } from 'react-router-dom';

// export default function Service({ data }) {
  
//    const navigate = useNavigate(); // Updated hook

//    // Function to handle card click and navigate to detail page
//    const handleCardClick = (item) => {
    
//     const filteredItem = {
//       ...item
//     };
    
//     navigate(`/services/${item.id}`,{ state: filteredItem }); // Updated navigation with filtered data
    
//   };

//   return (
//     <div className="bg-gray-50">
//       <div className="container mx-auto lg:px-2 pt-20 pb-28">
//         <Fade direction="right" triggerOnce>
//           <h1 className="text-4xl text-theme-blue text-center font-bold">Our Expertise</h1>
//         </Fade>
//         <Fade direction="left" triggerOnce>
//           <p className="font-light text-lg text-gray-400 text-center mb-12">
//           Unlock New Heights with Our Premium Service.
//           </p>
//         </Fade>

//         <div className="grid grid-rows-3 px-4 lg:px-10 gap-8 sm:grid-cols-3 sm:grid-rows-1 sm:gap-6 xl:gap-16">
//           {
//             data.map((item, index) => (
//               // eslint-disable-next-line react/no-array-index-key
//               <Fade direction={item.animation} delay={50 * index} key={index} triggerOnce>
//               <div className="bg-white group rounded-2xl shadow-2xl border">
//                 <div className="flex justify-center  cursor-pointer">
//                     <img 
//                     src={item.imageUrl} 
//                     alt="Service" 
//                     className="w-full rounded-t-lg transition-all duration-300 group-hover:scale-110 group-hover:rotate-6" 
//                     onClick={() => handleCardClick(item)}
//                     />
//                 </div>
//                 <h2 className="text-theme-blue text-center text-xl py-7 rounded-b-2xl">{item.title}</h2>
//                 </div>


//               </Fade>

              
//             ))
//           }
//         </div>
//       </div>
//     </div>
//   );
// }
