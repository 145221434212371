import React, { useContext, useEffect, useState } from 'react'

import Header from 'parts/Header';

import Footer from 'parts/Footer';
import {Internships  } from "json/landingPageData";
import InternshipHero from 'parts/InternshipHero';
import InternshipPost from 'parts/InternshipPost';
import { InternshipsContext } from 'context/InternshipContext';
import Load from 'parts/Load';


const InternshipPage = () => {
  const { internships, loading, error } = useContext(InternshipsContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (loading) return; // Skip if loading
    if (error) {
      setData(Internships); // Use static data when there's an error
    } else {
      setData(internships.data); // Use API data when available
    }
  }, [loading, error, internships]);

  // If still loading, show loading message
  if (loading) return <Load/>;


  return (
    <>
      <Header />
      <InternshipHero />
      <InternshipPost data={data}/>
      <Footer />
   </>
  )
}

export default InternshipPage




