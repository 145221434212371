    import React from "react";
    import { motion } from "framer-motion";
    import beat from '../assets/images/Product/beat.png';
    import kuppaiRaja from '../assets/images/Product/kuppaiRaja1.png';
    import InDine from '../assets/images/Product/inDine.png';
    import AstroHubAstrologer from '../assets/images/Product/AstroHubAstrologer.jpeg';
    import AstroHubUser from '../assets/images/Product/AstroHubUser.jpeg';
    import Ipoll from '../assets/images/Product/Ipoll.jpeg';
    import Hropal from '../assets/images/Product/Hropal.jpeg';
    import OhMyTennisCoach from '../assets/images/Product/OhMyTennisCoach.jpeg';
    import OhMyTennisUser from '../assets/images/Product/OhMyTennisUser.jpeg';
    import LetsFAME from '../assets/images/Product/LetsFAME.png';
    import Meeket from '../assets/images/Product/Meeket.png';
    import SagcoSmart from '../assets/images/Product/SagcoSmart.png';
    import advertMingo from '../assets/images/Product/advertMingo.png';
    import DeliverySGUser from '../assets/images/Product/DeliverySGUser.png';
    import DeliverSGRider from '../assets/images/Product/DeliverSGRider.png';
    import Paya from '../assets/images/Product/Paya.png';
    import DeliverySGMerchant from '../assets/images/Product/DeliverySGMerchant.png';

    const imageDetails = [
        // { src: beat, alt: "Beat Image", name: "BirthDay Beat" }, 
        { src: kuppaiRaja, alt: "KuppaiRaja Image", name: "KuppaiRaja" }, 
        { src: InDine, alt: "InDine Image", name: "InDine" }, 
        { src: AstroHubAstrologer, alt: "AstroHubAstrologer Image", name: "AstroHub Astrologer" }, 
        // { src: OhMyTennisUser, alt: "OhMyTennisUser Image", name: "OhMyTennis User" },
        // { src: Ipoll, alt: "Ipoll Image", name: "Ipoll" }, 
        { src: Hropal, alt: "Hropal Image", name: "Hropal" }, 
        // { src: OhMyTennisCoach, alt: "OhMyTennisCoach Image", name: "OhMyTennis Coach" },
        { src: AstroHubUser, alt: "AstroHubUser Image", name: "AstroHub User" },  
        // { src: LetsFAME, alt: "LetsFAME Image", name: "LetsFAME" },
        // { src: DeliverSGRider, alt: "DeliverSG Rider Image", name: "DeliverSG Rider" },
        // { src: Meeket, alt: "Meeket Image", name: "Meeket" },
        { src: SagcoSmart, alt: "SagcoSmart Image", name: "SagcoSmart" },
        // { src: advertMingo, alt: "Advert Mingo Image", name: "Advert Mingo" },
        // { src: DeliverySGUser, alt: "DeliverySG User Image", name: "DeliverySG User" },
        // { src: Paya, alt: "Paya Image", name: "Paya" },
        // { src: DeliverySGMerchant, alt: "DeliverySG Merchant Image", name: "DeliverySG Merchant" },
        
    ];

    export default function ProductIcon() {
        return (
            <div className="pt-10 py-8 container mx-auto overflow-hidden lg:px-5 mb-5">
                <div className="flex MyGradient">
                     <motion.div
                        initial={{ x: 0 }}
                        animate={{ x: "-100%" }}
                        transition={{ duration: 10, repeat: Infinity, ease: "linear" }}
                        className="flex flex-shrink-0"
                     >
                        {imageDetails.map((image, index) => (
                            <div key={index} className="flex flex-col items-center pr-10">
                                <img className="h-12 w-12" src={image.src} alt={image.alt} />
                                <p className="text-center text-sm font-medium text-gray-700 mt-2">{image.name}</p>
                            
                            </div>
                        ))}
                     </motion.div>

                     <motion.div
                        initial={{ x: 0 }}
                        animate={{ x: "-100%" }}
                        transition={{ duration: 10, repeat: Infinity, ease: "linear" }}
                        className="flex flex-shrink-0"
                     >
                        {imageDetails.map((image, index) => (
                            <div key={index} className="flex flex-col items-center pr-10">
                                <img className="h-12 w-12" src={image.src} alt={image.alt} />
                                <p className="text-center text-sm font-medium text-gray-700 mt-2">{image.name}</p>
                                
                            </div>
                        ))}
                    </motion.div>

                     <motion.div
                        initial={{ x: 0 }}
                        animate={{ x: "-100%" }}
                        transition={{ duration: 10, repeat: Infinity, ease: "linear" }}
                        className="flex flex-shrink-0"
                     >
                        {imageDetails.map((image, index) => (
                            <div key={index} className="flex flex-col items-center pr-10">
                                <img className="h-12 w-12" src={image.src} alt={image.alt} />
                                <p className="text-center text-sm font-medium text-gray-700 mt-2">{image.name}</p>
                                
                            </div>
                        ))}
                    </motion.div>
                </div>
            </div>
        );
    }
