import React from 'react';
import { useLocation } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import { WiStars } from 'react-icons/wi';

import { PiStarFourFill } from "react-icons/pi";

const ServiceDetail = () => {
  const location = useLocation();
  const { title, detail_title, detail_description, services = [] } = location.state || {}; // Default to an empty array

  // Assuming you want to display the first service in the array
  const mainService = services.length > 0 ? services[0] : null; // Get the first service or null if there are none

  return (
    <section className="hero flex flex-col sm:flex-row items-center justify-center sm:justify-between px-5 sm:px-12 lg:px-14 lg:gap-5 mt-0">
      <div className="flex flex-col w-full sm:w-2/4 mb-10 -mt-12">
        

        {mainService && ( // Check if mainService exists before accessing properties
        
          <>
            <Fade direction="up" triggerOnce >
              <div className="flex items-center">
                <h4 className="text-[18px] text-theme-purple mr-2">{mainService.short_title}</h4>
                <WiStars size={30} className="text-theme-purple" />
              </div>
            </Fade>
            <Fade direction="up" triggerOnce>
              <h1 className="text-3xl lg:text-5xl text-theme-blue font-bold leading-tight mb-6">
                {mainService.title} {/* Display the service title */}
              </h1>
            </Fade>

            <Fade direction="up" triggerOnce delay={400}>
              <p className="font-light text-xl text-gray-400 leading-relaxed">
                {mainService.description} {/* Display the service description */}
              </p>
            </Fade>
          </>
        )}
      </div>

      

      <div className="w-full sm:w-1/2 flex justify-center sm:justify-end -mt-5">
        <Fade direction="up" triggerOnce>
        {services.map((service) => (   
           <div class="p-6 mt-4"  key={service.id}>
            
                <ol class="relative border-s border-gray-200 dark:border-gray-700">  
                {service.service && service.service.map((subService) => (      
                    <li class="mb-10 ms-6" key={subService.id}>            
                        <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                        <PiStarFourFill className='text-theme-blue' />
                        </span>
                        <h3 class="flex items-center mb-1 text-lg font-semibold text-theme-blue dark:text-white">{subService.title} </h3>
                        <p class="mb-4 text-base font-normal text-gray-400 dark:text-gray-400">{subService.description}</p>
                    </li>
                 
                ))}
                </ol>
            </div>
           ))}
        </Fade>
        </div>
  

    </section>
  );
}

export default ServiceDetail;

