import React, { useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import heroService from 'assets/images/About/our-story.png';

export default function AboutScode() {
  const [isExpanded, setIsExpanded] = useState(false);

  const text = `Scode Software Solutions is a pioneering IT company headquartered in Cuddalore, with a mission to transform tier-3 cities into thriving technology hubs. As the first to develop native iOS applications in the region, Scode stands at the forefront of mobile and web development services. We are committed to decentralizing the tech industry by offering high-quality IT careers locally, demonstrating that world-class technology solutions can emerge from any location. Our dedication to nurturing talent from small towns ensures the development of a future-proof workforce while delivering top-tier software solutions that cater to a global clientele.`;

  const words = text.split(' ');
  const previewText = words.slice(0, 43).join(' ');
  const remainingText = words.slice(43).join(' ');

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <section className="flex flex-col lg:flex-row w-11/12 lg:w-11/12 pt-5 lg:pt-12 lg:mx-auto lg:items-start sm:items-center mx-auto">
      <div className="w-full sm:w-1/2 sm:pr-12">
        <Fade direction="up" triggerOnce>
          <img src={heroService} alt="Hero" className="w-full h-auto rounded-2xl" />
        </Fade>
      </div>
      
      <div className="w-full sm:w-1/2 flex flex-col pt-5 px-5 sm:mb-0 lg:mt-0">
        <Fade direction="up" triggerOnce>
          <h4 className="text-[18px] text-theme-purple lg:-mt-5 lg:mb-2 ">About Scode</h4>
        </Fade>
        <Fade direction="up" triggerOnce>
          <h1 className="text-[34px] text-theme-blue font-bold leading-tight mb-5">
            OUR STORY
          </h1>
        </Fade>
        <Fade direction="up" triggerOnce delay={400}>
          <p className="font-light text-xl text-gray-400 leading-relaxed">
            {isExpanded ? text : `${previewText}...`}
          </p>
          {words.length > 40 && (
            <button 
              onClick={toggleExpand} 
              className="text-theme-purple font-semibold mt-2">
              {isExpanded ? 'Show Less' : 'See More'}
            </button>
          )}
        </Fade>
      </div>
    </section>
  );
}
