import React, { Component, useContext, useEffect, useState } from 'react';

import Header from 'parts/Header';
import HeroProduct from 'parts/HeroProduct';
import Footer from 'parts/Footer';
import {
    Products
} from "json/landingPageData";

import ProductList from 'parts/ProductList';
import { ProductsContext } from 'context/ProductContext';
import Load from 'parts/Load';
//import { ActiveProjectContext } from "context/ActiveProjectContext";


const ProductPage = () => {
  const { products, loading, error } = useContext(ProductsContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (loading) return; // Skip if loading
    if (error) {
      setData(Products); // Set the error message to data
    } else {
      console.log('API Data:', products.data); // Log the data for debugging
      setData(products.data); // Use API data when available
    }
  }, [loading, error, products]);

  // If still loading, show loading message
  if (loading) return <Load />;

  return (
    <>
      <Header />
      <HeroProduct />
      <ProductList data={data} />
      <Footer />
    </>
  );
}

export default ProductPage;