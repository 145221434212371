import React, { useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import heroService from 'assets/images/About/people behind name.png';

export default function AboutScode() {
  const [isExpanded, setIsExpanded] = useState(false);
  const text = `We are more than just a tech company; we are a collection of passionate innovators committed to pushing the boundaries of what’s possible. Our team is driven by a relentless pursuit of excellence, constantly challenging conventional industry norms to deliver solutions that are not only effective but transformative. We thrive on creativity and embrace a hands-on approach to problem-solving, whether we're building custom software, crafting intuitive user experiences, or leading the charge in the latest digital trends, our goal remains the same: to redefine industry standards and set new benchmarks for success. At Scode, we don't just follow the path; we create it.`;
  const words = text.split(' ');
  const previewText = words.slice(0, 43).join(' ');
  const remainingText = words.slice(43).join(' ');

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };


  return (
    <section className="flex flex-col lg:flex-row w-11/12 lg:w-11/12 mt-5 lg:-mt-16 lg:mx-auto lg:items-start sm:items-center mx-auto">
     
      
      <div className="w-full sm:w-1/2 flex flex-col px-5 mb-5 sm:mb-0 lg:mr-8">
        <Fade direction="up" triggerOnce>
            <h4 className="text-[18px] text-theme-purple mb-2">Scode strategy</h4>
        </Fade>
        <Fade direction="up" triggerOnce>
          <h1 className="text-[34px] text-theme-blue font-bold leading-tight mb-5">
          The People Behind The Name
          </h1>
        </Fade>
        <Fade direction="up" triggerOnce delay={400}>

        <p className="font-light text-xl text-gray-400 leading-relaxed">
            {isExpanded ? text : `${previewText}...`}
        </p>

          {words.length > 40 && (
            <button 
              onClick={toggleExpand} 
              className="text-theme-purple font-semibold mt-2">
              {isExpanded ? 'Show Less' : 'See More'}
            </button>
          )}

          <p className="font-light text-xl text-gray-400 leading-relaxed ">
          
          </p>
        </Fade>
      </div>

      <div className="w-full sm:w-1/2 sm:pr-12 lg:mt-5">
        <Fade direction="up" triggerOnce>
          <img src={heroService} alt="Hero" className="w-full h-auto rounded-2xl" />
        </Fade>
      </div>
      
    </section>
  );
}
