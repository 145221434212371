import React, { useContext, useEffect, useState } from 'react'
import Header from 'parts/Header';

import Footer from 'parts/Footer';
import JobPost from 'parts/JobPost';
import JobHero from 'parts/JobHero';
import {Jobs} from "json/landingPageData";
import { JobsContext } from 'context/JobContext';
import Load from 'parts/Load';

const JobPage = () => {
  const { jobs, loading, error } = useContext(JobsContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (loading) return; // Skip if loading
    if (error) {
      setData(Jobs); // Use static data when there's an error
    } else {
      setData(jobs.data); // Use API data when available
    }
  }, [loading, error, jobs]);

  // If still loading, show loading message
  if (loading) return <Load />;


  return (
     <>
        <Header />
        <JobHero />
        <JobPost data={data}/>
        <Footer />
    </>
  )
}

export default JobPage





