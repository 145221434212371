import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../api/api';

const JobApplicationForm = () => {
  const location = useLocation();
  const job = location.state || { title: 'Default Job Title' };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    resume: null,
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, resume: file });
    }
  };

  const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('phone', formData.phone);
        formDataToSend.append('message', formData.message);
        formDataToSend.append('resume', formData.resume);
        formDataToSend.append('job_title', job.title);

        try {
            // Send the form data to your Django backend
            const response = await api.jobapply.createJobApplication(formDataToSend);

            // Log the response for debugging
            console.log('Response:', response);

            // Assuming the response contains a resume URL
            if (response.resume_url) {
                console.log('Resume URL:', response.resume_url);
                const emailParams = {
                    to_name: 'HR',
                    from_name: formData.name,
                    job_title: job.title,
                    message: formData.message,
                    phone: formData.phone,
                    resume_url: response.resume_url,  // Use the resume URL from the response
                };

                await emailjs.send(
                    'service_hob28ca', 
                    'template_q3mv2zb', 
                    emailParams,
                    'rsVBSv1CaAIgW6JM7'  
                );

              //   await emailjs.send(
              //     'service_yunctmy', 
              //     'template_ap4qc0e', 
              //     emailParams,
              //     'BVJ8DdUzfbhELkCP7'  
              // );


                toast.success('Application submitted successfully!', { autoClose: 3000 });

                // Clear the form fields
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    resume: null,
                    message: '',
                });
            } else {
                throw new Error('Resume URL not returned in the response.');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error(error.message || 'There was an error submitting the application.', { autoClose: 3000 });
        }
    };

  return (
    <div className="container mx-auto">
      <form
        onSubmit={handleSubmit}
        className="max-w-md mx-auto bg-white p-8 rounded-lg shadow-lg"
        noValidate
      >
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-theme-purple"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-theme-purple"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="phone" className="block text-gray-700">Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-theme-purple"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="message" className="block text-gray-700">Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-theme-purple"
            rows="4"
            required
          ></textarea>
        </div>
        <div className="mb-4">
          <label htmlFor="resume" className="block text-gray-700">Resume</label>
          <input
            type="file"
            id="resume"
            name="resume"
            onChange={handleFileChange}
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-theme-purple"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-theme-purple text-white px-4 py-2 rounded-full transition duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-purple"
        >
          Submit
        </button>
      </form>
      <ToastContainer className="!z-50" />
    </div>
  );
};

export default JobApplicationForm;
