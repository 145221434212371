// src/context/ServicesContext.js
import React, { createContext, useState, useEffect } from "react";
import api from "../api/api";

// Create the context
export const ServicesContext = createContext();

// Create the context provider component
export const ServicesProvider = ({ children }) => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch all services from the API
  const fetchServices = async () => {
    try {
      const response = await api.services.fetchServices();
      setServices(response);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  // Fetch services when the component mounts
  useEffect(() => {
    fetchServices();
  }, []);

  return (
    <ServicesContext.Provider value={{ services, loading, error }}>
      {children}
    </ServicesContext.Provider>
  );
};

