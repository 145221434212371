
import React, { useState, useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import { useNavigate } from 'react-router-dom'; 
import ProjectBanner from './ProjectBanner';

export default function ProductList({ data }) {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState({});
  const navigate = useNavigate();

  // Initialize the image index when the API data is available
  useEffect(() => {
    if (data && data.length > 0) {
      const initialImageIndex = data.reduce((acc, _, index) => {
        acc[index] = 0; // Initialize to the first image
        return acc;
      }, {});
      setCurrentImageIndex(initialImageIndex); // Update the state once data is available
    }
  }, [data]); // Re-run whenever the data changes

  useEffect(() => {
    // Cleanup interval on unmount
    return () => {
      if (hoveredIndex?.intervalId) clearInterval(hoveredIndex.intervalId);
    };
  }, [hoveredIndex]);

  const handleMouseEnter = (index, totalImages) => {
    setHoveredIndex(index);
    const intervalId = setInterval(() => {
      setCurrentImageIndex(prev => ({
        ...prev,
        [index]: (prev[index] + 1) % totalImages, // Cycle through images
      }));
    }, 800);
    setHoveredIndex({ index, intervalId });
  };

  const handleMouseLeave = () => {
    if (hoveredIndex?.intervalId) clearInterval(hoveredIndex.intervalId);
    setHoveredIndex(null);
    setCurrentImageIndex(prev => ({
      ...prev,
      [hoveredIndex?.index]: 0, // Reset to the first image
    }));
  };

  const handleCardClick = (item) => {
    const filteredItem = {
      ...item,
      tech: item.tech?.map(techItem => ({ id: techItem.id, name: techItem.name })) || [], // Safely map or fallback to empty array
    };
    navigate(`/product/${item.id}`, { state: filteredItem });
  };
  
  

  if (!data || data.length === 0) {
    return <p>No products available.</p>; // Handle empty data case
  }

  return (
    <div className="">
      <div className="container mx-auto pt-16 pb-28 lg:w-11/12 lg:h-4/5">
        <div className="grid grid-cols-1 px-4 lg:px-10 gap-8 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:gap-16">
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <Fade direction={item.animation} delay={50 * index} triggerOnce>
                <div>
                  <div
                    className="bg-white group rounded-2xl shadow-2xl border transform transition duration-500 hover:scale-105 cursor-pointer"
                    onMouseEnter={() => handleMouseEnter(index, item.images.length)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => handleCardClick(item)}
                  >
                    <h2 className="text-theme-blue text-center text-xl py-4 rounded-b-2xl">{item.title}</h2>
                    <div className="relative h-56 sm:h-64 xl:h-80 2xl:h-96 overflow-hidden">
                      {item.images.map((image, imgIndex) => (
                        <img
                          key={image.id}
                          src={image.imageUrl}
                          alt={`Product ${item.title} Image ${imgIndex + 1}`}
                          className={`absolute w-full h-full object-cover transition-opacity duration-500 ${
                            currentImageIndex[index] === imgIndex ? 'opacity-100' : 'opacity-0'
                          }`}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </Fade>
              {(index + 1) % 4 === 0 && (
                <div className="col-span-full">
                  <Fade direction="up" delay={500 * index} triggerOnce>
                    <ProjectBanner />
                  </Fade>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}








// import React, { useState } from 'react';
// import { Fade } from 'react-awesome-reveal';
// import { useNavigate } from 'react-router-dom'; // Updated import
// import ProjectBanner from './ProjectBanner';

// export default function ProductList({ data }) {
//   // State to manage which card is currently hovered
//   const [hoveredIndex, setHoveredIndex] = useState(null);

//   // Initialize the currentImageIndex state to show the first image for each card by default
//   const [currentImageIndex, setCurrentImageIndex] = useState(
//     data.reduce((acc, item, index) => {
//       acc[index] = 0; // Set the default image index to 0 for each card
//       return acc;
//     }, {})
//   );

//   const navigate = useNavigate(); // Updated hook

//   // Function to handle mouse enter event for a card
//   const handleMouseEnter = (index, totalImages) => {
//     setHoveredIndex(index);
//     // Start changing images on hover
//     const intervalId = setInterval(() => {
//       setCurrentImageIndex(prev => ({
//         ...prev,
//         [index]: (prev[index] || 0) + 1 < totalImages ? (prev[index] || 0) + 1 : 0
//       }));
//     }, 800); // Change image every 800ms
//     // Save the interval ID to clear it on mouse leave
//     setHoveredIndex({ index, intervalId });
//   };

//   // Function to handle mouse leave event for a card
//   const handleMouseLeave = () => {
//     if (hoveredIndex && hoveredIndex.intervalId) {
//       clearInterval(hoveredIndex.intervalId);
//     }
//     setHoveredIndex(null);
//     // Reset to the first image when the mouse leaves
//     setCurrentImageIndex(prev => ({
//       ...prev,
//       [hoveredIndex?.index]: 0
//     }));
//   };

//   // Function to handle card click and navigate to detail page
//   const handleCardClick = (item) => {
//     // Create a copy of the item and filter out non-serializable data
//     const filteredItem = {
//       ...item,
//       tech: item.tech.map(techItem => ({ id: techItem.id, name: techItem.name })), // Exclude the icon component
//     };
    
//     navigate(`/product/${item.id}`, { state: filteredItem }); // Updated navigation with filtered data
//   };


//   return (
//     <div className="">
//       <div className="container mx-auto pt-16 pb-28 lg:w-11/12 lg:h-4/5">
//         <div className="grid grid-cols-1 px-4 lg:px-10 gap-8 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:gap-16">
//           {data.map((item, index) => (
//             <React.Fragment key={index}>
//               <Fade direction={item.animation} delay={50 * index} triggerOnce>
//                 <div>
//                   <div
//                     className="bg-white group rounded-2xl shadow-2xl border transform transition duration-500 hover:scale-105 cursor-pointer"
//                     onMouseEnter={() => handleMouseEnter(index, item.images.length)}
//                     onMouseLeave={handleMouseLeave}
//                     onClick={() => handleCardClick(item)} // Add click event handler
//                   >
//                     <h2 className="text-theme-blue text-center text-xl py-4 rounded-b-2xl">{item.title}</h2>
//                     <div className="relative h-56 sm:h-64 xl:h-80 2xl:h-96 overflow-hidden">
//                       {item.images.map((image, imgIndex) => (
//                         <img
//                           key={image.id}
//                           src={image.imageUrl}
//                           alt={`Product ${item.title} Image ${image.id}`}
//                           className={`absolute w-full h-full object-cover transition-opacity duration-500 ${currentImageIndex[index] === imgIndex ? 'opacity-100' : 'opacity-0'}`}
//                         />
//                       ))}
//                     </div>
//                   </div>
//                 </div>
//               </Fade>
//               {(index + 1) % 4 === 0 && (
//                 <div className="col-span-full">
//                   <Fade direction="up" delay={500 * index} triggerOnce>
//                     <ProjectBanner />
//                   </Fade>
//                 </div>
//               )}
//             </React.Fragment>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }




