import React, { useContext, useEffect, useState } from "react";
import Header from 'parts/Header';
import Footer from 'parts/Footer';
import ServiceTab from 'parts/ServiceTab'; // Ensure the path is correct
import HeroService from "parts/HeroService";
import { Services } from "json/landingPageData"; // Import your static data
import { ServicesContext } from "context/ServiceContext"; // Import your context
import Load from "parts/Load";

const ServicePage = () => {
  const { services, loading, error } = useContext(ServicesContext);
  const [data, setData] = useState([]); // State to hold the services data

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (loading) return; // Skip if loading
    if (error) {
      setData(Services); // Use static data when there's an error
    } else {
      setData(services.data); // Use API data when available
    }
  }, [loading, error, services]);

  // If still loading, show loading message
  if (loading) return <Load/>;

  return (
    <>
      <Header />
      <HeroService />
      <ServiceTab data={data} /> {/* Pass the determined data */}
      <Footer />
    </>
  );
};

export default ServicePage;




