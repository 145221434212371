import React, { useState, useEffect } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FaUserTie } from "react-icons/fa";
import BrandLogo from "../assets/images/scode_logo1.png";

const InfoTooltip = () => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  useEffect(() => {
    // Show tooltip after a delay (e.g., 10 seconds)
    const timer = setTimeout(() => {
      setTooltipVisible(true);
    }, 10000);

    // Cleanup function to clear the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []); // Empty dependency array to run this effect only once (like componentDidMount)

  const handleCloseTooltip = () => {
    setTooltipVisible(false); // Close the tooltip
  };

  return (
    tooltipVisible && (
      <>
        <div
          className="fixed p-10 bg-gradient-to-r from-blue-500 to-teal-500 shadow-lg transform "
          style={{
            right: "90px", // Adjust as needed to stick the tooltip at a certain horizontal point
            top: "250px", // Adjust as needed to stick the tooltip at a certain vertical point
            width: "16rem", // Keep the width to 64 (16rem) units
            padding: "1rem", // Add padding to the tooltip
            borderRadius: "0.5rem", // Rounded borders for a card-like design
            zIndex: 1000, // Ensure the tooltip is on top of other content
          }}
        >
          <div className="flex justify-between items-center mb-2">
            <span className="font-semibold text-white">Scode Support Team</span>
            {/* <span className="text-xs text-gray-200">2 minutes ago</span> */}
          </div>

          <p className="text-sm text-gray-100 mb-2">
            Greetings from Scode software solutions !!
            <br />
            Are you looking for a Mobile App / Web Application / Digital Marketing
            Solutions for your company? You're in the right place.
          </p>
          <p className="text-sm text-gray-100">
            You can call us at <strong>+91 9629692276</strong> or email us at:
            <br />
            <a href="mailto:tyson@scodesoft.com" className="text-blue-200 underline">
              tyson@scodesoft.com
            </a>
            <br />
            <a href="mailto:hr@scodesoft.com" className="text-blue-200 underline">
              hr@scodesoft.com
            </a>
          </p>

          {/* Close Icon */}
          <button
            className=" absolute -top-7 -right-7 text-gray-200 hover:text-gray-400"
            onClick={handleCloseTooltip}
          >
            <IoIosCloseCircleOutline 
              style={{ background: 'linear-gradient(to right, #3b82f6, #14b8a6)', borderRadius: '50%' }} 
              size={28} 
            />

          </button>
          
             {/* Tooltip Arrow */}
            <div
            className="tooltip-arrow -mt-8"
            style={{
              position: 'absolute',
              width: '0',
              height: '0',
              borderLeft: '15px solid transparent',
              borderRight: '15px solid transparent',
              borderTop: '90px solid transparent',
              borderBottom: '20px solid #17B5AC', // Match the tooltip background color
              right: '-65px', // Position it slightly inside the tooltip
              top: '100%', // Position it below the tooltip
              transform: 'translateY(-50%) rotate(90deg)', // Adjust rotation for bottom arrow
            }}
            
          ></div>
         
          <div className=" w-[65px] h-[65px] ml-[247px] -mt-10 rounded-full absolute spin-button">
            <img
              className=" w-[65px] h-[65px] object-cover absolute  "
              src={BrandLogo}
              alt="Build Website logo"
            />
          </div>
         

        </div>

     
       
      </>
    )
  );
};

export default InfoTooltip;



// <button
// type="button"
// className="mb-2 md:mb-0 ml-[260px]  text-white bg-gradient-to-r from-blue-500 to-teal-500  font-medium rounded-lg text-sm px-2 py-2 text-center"
// >

//   <img
//     className=" w-[65px] h-[65px] object-cover absolute "
//     src={BrandLogo}
//     alt="Build Website logo"
//   />

// {/* <FaUserTie size={20}/> */}
// </button>