
import React from 'react';

import { Fade } from 'react-awesome-reveal';

import heroService from 'assets/images/Services/services page.png';

export default function HeroService() {
  return (
    <section className="hero flex flex-col lg:flex-row items-center justify-between lg:px-2">
      <div className=" w-full lg:w-full xl:pl-12 sm:pr-2">
        <Fade direction="up" triggerOnce>
          <h1 className="text-5xl lg:text-6xl sm:text-5xl text-theme-blue font-bold leading-tight mb-5">
          Innovative Solutions,
          Infinite Possibilities
          </h1>
        </Fade>
        <Fade direction="up" triggerOnce delay={400}>
          <p className="font-light text-xl text-gray-400 leading-relaxed mb-16">
          Empowering your vision with cutting-edge development solutions that deliver impactful, user-focused digital experiences across web, mobile, and beyond.
          </p>
        </Fade>
      </div>

      <div className="flex pt-6 lg:pt-2 md:w-fit w-full justify-center items-center lg:w-full ">
        <Fade direction="up" triggerOnce>
          <img src={heroService} alt="Hero" className='w-auto' />
        </Fade>
      </div>

    </section>
  );
}
