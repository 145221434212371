import AboutBanner from 'parts/AboutBanner'
import Footer from 'parts/Footer'
import Header from 'parts/Header'
import IndustriesServe from 'parts/IndustriesServe'
import ProblemWeSolve from 'parts/ProblemWeSolve'
import WhoWeServe from 'parts/WhoWeServe'
import React, { useEffect } from 'react'

const SolutionsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []); // Empty dependency array means this effect runs once on mount


  return (
     <>
        <Header />
        <AboutBanner />
        <WhoWeServe />
        <IndustriesServe />
        <ProblemWeSolve />
        <Footer />
    </>
  )
}

export default SolutionsPage