import { useLocation } from 'react-router-dom';
import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { WiStars } from "react-icons/wi";
import { BiDetail } from "react-icons/bi";


import IntershipApplyForm from 'parts/IntershipApplyForm';
const JobApplyDetail = () => {
    const location = useLocation();
    const { title, description } = location.state;

    return (
        <section className="hero  lg:ml-14 flex flex-col sm:flex-row items-center justify-center sm:justify-between px-5 sm:px-12 lg:px-28 mt-20">
            <div className="flex flex-col w-full sm:w-4/5 mb-10 -mt-12">
                <Fade direction="up" triggerOnce>
                    <div className="flex mb-5">
                        <h4 className="text-2xl text-theme-purple mr-2">Careers</h4>
                        <WiStars size={35} className="text-theme-purple" />
                    </div>
                </Fade>
    
                <Fade direction="up" triggerOnce>
                    <h1 className="text-4xl text-theme-blue font-bold leading-tight mb-6">
                        {title}
                    </h1>
                </Fade>

                <Fade direction="up" triggerOnce>
                    <div className="flex mb-5">
                        <h4 className="text-2xl text-theme-blue mr-2">Overview</h4>
                        <BiDetail size={30} className="mr-2 text-theme-blue" />
                    
                       
                    </div>
                </Fade>
    
                <Fade direction="up" triggerOnce delay={400}>
                    <ul className="font-light text-xl text-gray-400 leading-relaxed list-disc pl-5">
                        {description.map((item, index) => (
                            <li key={index} className="mb-2">{item}</li>
                        ))}
                    </ul>
                </Fade>
            </div>

            <div className="w-full ">
                <Fade direction="up" triggerOnce>
                 <IntershipApplyForm />
                </Fade>
           </div>
        </section>
    );
};

export default JobApplyDetail;
