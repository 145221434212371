import React, { useEffect, useState } from 'react';
import { FaRocketchat } from "react-icons/fa";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import api from "../api/api"; // Import your API service here


const faqData = {
  "General Questions about the Company": [
  {
  question: "What services does your company offer?",
  answer: "We offer a range of services including web development,mobile app development,UI/UX development,Backend API development and digital marketing."
  },
  {
  question: "Where is your company located?",
  answer: "Our company is located in Cuddalore."
  },
  {
  question: "How can I contact your support team?",
  answer: "You can reach our support team at hr@scodesoft.com."
  },
  {
  question: "What are your business hours?",
  answer: "We operate from 9:30 AM to 6:30 PM, Monday to Friday."
  },
  {
  question: "Do you offer free consultations or demos?",
  answer: "Yes, we offer free consultations to discuss your project needs."
  },
  ],
  "Web Application Development Questions": [
  {
  question: "What types of web applications do you develop?",
  answer: "We develop custom web applications tailored to your business needs."
  },
  {
  question: "Do you provide both frontend and backend development?",
  answer: "Yes, we offer both frontend and backend development services."
  },
  {
  question: "What technologies do you use for web development?",
  answer: "We primarily use React, Node.js, and Django for web development."
  },
  {
  question: "Can you integrate third-party APIs into web apps?",
  answer: "Yes, we can integrate various third-party APIs based on your requirements."
  },
  {
  question: "How long does it take to develop a custom web application?",
  answer: "The timeline varies based on the complexity, but we will provide a clear estimate."
  },
  {
  question: "Do you provide post-launch support for web applications?",
  answer: "Yes, we offer post-launch support and maintenance services."
  }
  ],
  "Mobile Application Development Questions": [
  {
  question: "Do you develop mobile apps for both Android and iOS?",
  answer: "Yes, we develop mobile apps for both Android and iOS platforms."
  },
  {
  question: "What is the difference between native and Flutter apps?",
  answer: "Native apps are built specifically for a platform, while Flutter allows cross-platform development with a single codebase."
  },
  {
  question: "Can you convert an existing web app into a mobile app?",
  answer: "Yes, we can convert your web application into a mobile app."
  },
  {
  question: "How do you ensure app security and performance?",
  answer: "We follow best practices for security and optimize the app for performance."
  },
  {
  question: "Do you provide app store publishing services?",
  answer: "Yes, we handle the publishing process for both Google Play and the App Store."
  },
  {
  question: "How much does mobile app development cost?",
  answer: "The cost depends on the app’s complexity and features, and we provide detailed estimates during the consultation."
  }
  ],
  "UI/UX Design Services": [
  {
  question: "What is the process for UI/UX design?",
  answer: "Our design process includes research, wireframing, prototyping, and user feedback."
  },
  {
  question: "Do you provide design prototypes before development?",
  answer: "Yes, we provide interactive prototypes before starting development."
  },
  {
  question: "Can you redesign an existing app or website?",
  answer: "Yes, we offer redesign services for existing apps and websites."
  },
  {
  question: "How do you ensure the design is user-friendly?",
  answer: "We conduct user research and usability testing to ensure a seamless experience."
  },
  {
  question: "Do you offer usability testing services?",
  answer: "Yes, we provide usability testing to gather feedback and improve the design."
  }
  ],
  "Digital Marketing Services": [
  {
  question: "What digital marketing services do you offer?",
  answer: "We offer SEO, social media marketing, PPC, email marketing, and content marketing."
  },
  {
  question: "Do you provide SEO services for websites and apps?",
  answer: "Yes, we offer SEO services to improve visibility and rankings."
  },
  {
  question: "Can you help with social media marketing?",
  answer: "Yes, we manage social media campaigns to grow your online presence."
  },
  {
  question: "How do you measure the success of your marketing campaigns?",
  answer: "We use analytics tools to track key metrics and provide detailed reports."
  },
  {
  question: "Do you offer Pay-Per-Click (PPC) or email marketing?",
  answer: "Yes, we provide both PPC and email marketing services."
  }
  ],
  "Internship Programs": [
  {
  question: "Do you offer internship programs for students?",
  answer: "Yes, we offer internship programs for students to gain hands-on experience."
  },
  {
  question: "What are the eligibility criteria for internships?",
  answer: "Eligibility criteria vary by program, but a passion for learning is key."
  },
  {
  question: "How long do your internships last?",
  answer: "Our internships typically last between 3 to 6 months."
  },
  {
  question: "Do you provide certificates or job offers post-internship?",
  answer: "Yes, we provide certificates and may offer job placements based on performance."
  },
  {
  question: "Can interns work remotely?",
  answer: "Yes, we offer remote internship opportunities."
  },
  {
  question: "How can I apply for an internship?",
  answer: "You can apply by sending your resume to hr@scodesoft.com."
  }
  ],
  "Customer Support and Project Management": [
  {
  question: "How do you handle customer feedback and support issues?",
  answer: "We have a dedicated support team to handle feedback and resolve issues promptly."
  },
  {
  question: "What project management tools do you use?",
  answer: "We use tools like Jira, Trello, and Asana for project management."
  },
  {
  question: "How often will I receive project updates?",
  answer: "We provide regular updates weekly or as per your preference."
  },
  {
  question: "What is your process for handling change requests?",
  answer: "We assess change requests and provide a timeline and cost estimate."
  },
  {
  question: "Do you offer maintenance services for existing projects?",
  answer: "Yes, we provide ongoing maintenance services for all projects."
  }
  ],
  "Pricing and Payment Queries": [
  {
  question: "How is the cost of a project estimated?",
  answer: "We estimate costs based on scope, features, and timeline."
  },
  {
  question: "Do you offer any payment plans or financing options?",
  answer: "Yes, we provide flexible payment plans for large projects."
  },
  {
  question: "What payment methods do you accept?",
  answer: "We accept bank transfers, credit cards, and online payments."
  },
  {
  question: "Are there any discounts for long-term projects?",
  answer: "Yes, we offer discounts for long-term collaborations."
  },
  {
  question: "Do you provide free trials or discounts on services?",
  answer: "We offer discounts on select services during promotions."
  }
  ],
  "Customer Onboarding and Rise Requests": [
  {
  question: "How can I raise a request for a new project?",
  answer: "You can submit a request via our website or contact our team."
  },
  {
  question: "What is the process after submitting a service request?",
  answer: "Our team will review your request and contact you within 24 hours."
  },
  {
  question: "How soon can we start once I submit a request?",
  answer: "We can start as soon as the project scope is finalized."
  },
  {
  question: "Can I track the status of my request online?",
  answer: "Yes, you will receive a tracking link to monitor progress."
  },
  {
  question: "Is there a point of contact for my ongoing project?",
  answer: "Yes, you will have a dedicated project manager as your point of contact."
  },
  ],
  "Technical and Development FAQs": [
  {
  question: "What platforms do you support for mobile apps?",
  answer: "We support Android, iOS, and cross-platform development."
  },
  {
  question: "Do you provide cloud-based solutions?",
  answer: "Yes, we offer cloud integration and hosting services."
  },
  {
  question: "Can you migrate my existing system to a new platform?",
  answer: "Yes, we provide system migration services with minimal downtime."
  },
  {
  question: "What coding standards do your developers follow?",
  answer: "Our developers follow industry best practices and coding standards."
  }
  ]
  };


const ChatBot = () => {
  const [isChatboxOpen, setIsChatboxOpen] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [userData, setUserData] = useState({ username: '', phone: '' });
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const toggleChatbox = () => setIsChatboxOpen((prev) => !prev);

  const addMessage = (content, sender = "user") => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { content, sender },
    ]);
  };

  const handleTitleClick = (title) => {
    setSelectedTitle(title);
    setSelectedQuestion(null);
  };

  const handleQuestionClick = (questionData) => {
    setSelectedQuestion(questionData);
    addMessage(questionData.question);
    setIsTyping(true);

    setTimeout(() => {
      addMessage(questionData.answer, "bot");
      setIsTyping(false);
    }, 1500);
  };

  const handleContinue = () => {
    setSelectedQuestion(null);
  };

  const handleQuit = () => {
    setSelectedTitle(null);
    setSelectedQuestion(null);
    setMessages([]); // Clear messages on quit
  };

  const handleSendMessage = async (event) => {
      event.preventDefault();
      const userInput = event.target.elements["user-input"].value.trim();
      if (userInput) {
          addMessage(userInput);

          // Simulate typing delay for the bot's response
          setIsTyping(true);

          // Find the corresponding answer for the user input
          const matchedQuestion = faqData[selectedTitle]?.find(q => q.question.toLowerCase() === userInput.toLowerCase());
          let botResponse = "Thank you for your question! We will get back to you soon.";

          if (matchedQuestion) {
              botResponse = matchedQuestion.answer;
          }

          setTimeout(() => {
              addMessage(botResponse, "bot");

              // Save the user's question and answer to the API
              const inputData = {
                  user: isLoggedIn ? userData.id : '', // Use user ID from state
                  question: userInput,
                  Answer: botResponse
              };

              // Call API to save Q&A
              api.question_and_answer.createQaApplication(inputData)
                  .then(response => {
                      if (response.success) {
                          console.log("Question and answer saved successfully");
                      } else {
                          console.error("Failed to save question and answer");
                      }
                  })
                  .catch(err => {
                      console.error("Error saving question and answer:", err);
                  });

              setIsTyping(false);
          }, 1500);

          event.target.reset();
      }
  };


  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    const { username, phone } = userData;
    
    if (username && phone) {
        const inputData = { username, phone };
        try {
            const response = await api.regsiter.createJobApplication(inputData);
            if (response.success) {
                // Save user data including the ID
                setUserData(prevData => ({
                    ...prevData,
                    id: response.data.id, // Save user ID
                }));
                addMessage("Registration successful! Welcome, " + username + "!");
                setIsLoggedIn(true); // Update login status
                setSelectedTitle(null); // Show all categories after registration
                setSelectedQuestion(null);
            }
        } catch (error) {
            addMessage("Registration failed. Please try again.");
        }
    } else {
        addMessage("Please provide a valid name and phone number.");
    }
};


  return (
    <>
      {/* Chat button */}
      <div className="fixed bottom-0 left-1 md:left-5 mb-8 md:mb-11 ml-4 z-50">
        <button
          id="open-chat"
          className="bg-gradient-to-r from-blue-500 to-teal-500 text-white p-4 rounded-full hover:bg-[#0f1c8a] transition duration-300 flex items-center justify-center"
          onClick={toggleChatbox}
        >
          <FaRocketchat className="text-2xl" />
        </button>
      </div>

      {/* Chat container */}
      {isChatboxOpen && (
        <div
          id="chat-container"
          className="fixed bottom-[98px] md:bottom-20 left-7 md:left-24 w-[340px] md:w-80 bg-white shadow-md rounded-lg z-50"
          style={{ minHeight: '350px', maxHeight: '500px' }}
        >
          <div className="p-3 border-b bg-gradient-to-r from-blue-500 to-teal-500 text-white rounded-t-lg flex justify-between items-center">
            <p className="text-lg font-semibold">ScoBot</p>
            <button onClick={toggleChatbox} className="text-gray-300 hover:text-gray-400">
              <IoIosCloseCircleOutline size={34} />
            </button>
          </div>

          <div
            className="p-4 h-80 overflow-y-auto"
            style={{
              overflowY: 'scroll',
              scrollbarWidth: 'none',
              '-ms-overflow-style': 'none',
            }}
            onWheel={(e) => e.currentTarget.scrollTo({ top: e.currentTarget.scrollTop + e.deltaY, behavior: 'smooth' })}
          >
            {messages.map((msg, index) => (
              <div key={index} className={`mb-2 ${msg.sender === "user" ? "text-right" : "text-left"}`}>
                <p
                  className={`${
                    msg.sender === "user"
                    ? "bg-blue-500 text-white text-sm inline-block self-end"
                    : "bg-gray-200 text-gray-700 text-sm inline-block self-start"
                  } rounded-lg py-2 px-3 max-w-[85%]`}
                  style={{
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {msg.content}
                </p>
              </div>
            ))}

            {isTyping && (
              <div className="text-left text-sm text-gray-500">Typing...</div>
            )}

            {!isLoggedIn ? (
              // Render login form if not logged in
              <form onSubmit={handleLoginSubmit} className="flex flex-col">
                <input
                  type="text"
                  placeholder="Name"
                  value={userData.username}
                  onChange={(e) => setUserData({ ...userData, username: e.target.value })}
                  className="border p-2 mb-2 rounded"
                />
                <input
                  type="text"
                  placeholder="Phone"
                  value={userData.phone}
                  onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
                  className="border p-2 mb-2 rounded"
                />
                <button
                  type="submit"
                  className="bg-gradient-to-r from-blue-500 to-teal-500 text-white px-4 py-2 rounded"
                >
                  Start Chat
                </button>
              </form>
              
            ) : !selectedTitle ? (
              Object.keys(faqData).map((title) => (
                <button
                  key={title}
                  onClick={() => handleTitleClick(title)}
                  className="flex justify-between items-center w-full text-left text-blue-600 text-sm py-2 px-2 border-b"
                >
                  <span>{title}</span>
                  <MdOutlineKeyboardArrowRight size={22} />
                </button>
              ))
            ) : selectedQuestion ? (
              <div>
                <div className="inline-flex justify-between mt-2 p-4 space-x-4">
                  <button onClick={handleContinue} className="text-blue-500">
                    Continue
                  </button>
                  <button onClick={handleQuit} className="text-red-500">
                    Quit
                  </button>
                </div>
              </div>
            ) : (
              faqData[selectedTitle].map((questionData, index) => (
                <button
                  key={index}
                  onClick={() => handleQuestionClick(questionData)}
                  className="flex justify-between items-center w-full text-left text-sm py-2 px-2 border-b "
                  style={{ color: '#152C5B' }}
                >
                  {questionData.question}
                </button>
              ))
            )}
          </div>
          {isLoggedIn && (
            <form onSubmit={handleSendMessage} className="flex p-2 border-t">
              <input
                type="text"
                name="user-input"
                placeholder="Ask your question..."
                className="border p-2 rounded w-full"
              />
              <button
                type="submit"
                className="bg-gradient-to-r from-blue-500 to-teal-500 text-white px-4 py-2 rounded ml-2"
              >
                Send
              </button>
            </form>
          )}
        </div>
      )}
    </>
  );
};

export default ChatBot;
