import Blogs from 'parts/Blogs'
import Footer from 'parts/Footer'
import Header from 'parts/Header'
import React, { useContext, useEffect, useState } from 'react'
import { blogPosts } from "json/landingPageData"; // Import your static data
import { BlogsContext } from 'context/BlogContext';
import Load from 'parts/Load';


const BlogPage = () => {
    const { blogs, loading, error } = useContext(BlogsContext);
    const [data, setData] = useState([]); // State to hold the services data

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (loading) return; // Skip if loading
        if (error) {
        setData(blogPosts); // Use static data when there's an error
        } else {
        setData(blogs.data); // Use API data when available
        }
    }, [loading, error, blogs]);

    // If still loading, show loading message
    if (loading) return <Load/>;

  return (
    <>
    <Header/>
    <Blogs data={data}  />
 
    <Footer />
  </>
  )
}

export default BlogPage