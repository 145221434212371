import React, { useEffect, useState } from 'react';
import api from '../api/api'; // Import your API module

const Comment = ({ blogPostId }) => {
  const [name, setName] = useState('');
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);
  const [visibleComments, setVisibleComments] = useState(3); // State for visible comments

  // Fetch comments when the component mounts
  useEffect(() => {
    const loadComments = async () => {
      try {
        const fetchedComments = await api.blogs.fetchComments(blogPostId);
        setComments(fetchedComments);
      } catch (error) {
        console.error('Failed to load comments:', error);
      }
    };

    loadComments();
  }, [blogPostId]);

  const handleCommentSubmit = async (e) => {
    e.preventDefault();

    if (name.trim() && comment.trim()) {
      const newComment = {
        blog_comment: blogPostId,
        user_name: name,
        comment,
      };

      try {
        const response = await api.blogs.addComment(newComment);
        const addedComment = response.data || response;

        // Prepend the new comment to the beginning of the list
        setComments((prevComments) => [addedComment, ...prevComments]);

        // Clear input fields
        setName('');
        setComment('');
      } catch (error) {
        console.error('Failed to add comment:', error);
      }
    }
  };

  const handleViewMore = () => {
    setVisibleComments((prev) => prev + 3); // Increase visible comments by 5
  };

  return (
    <div className="flex justify-center items-center mt-5 ">
      <div className="w-full max-w-md p-8 border rounded-md shadow-lg">
        <h3 className="text-xl font-semibold mb-4 text-center">Comments</h3>
        <form onSubmit={handleCommentSubmit} className="space-y-2 mb-4">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full border rounded px-4 py-2 focus:outline-none"
            placeholder="Your name..."
          />
          <input
            type="text"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className="w-full border rounded px-4 py-2 focus:outline-none"
            placeholder="Add a comment..."
          />
          <button
            type="submit"
            className="bg-theme-purple text-white px-4 py-2 rounded hover:bg-theme-blue w-full"
          >
            Post Comment
          </button>
        </form>

        <ul className="space-y-4">
          {comments.slice(0, visibleComments).map((cmt) => (
            <li key={cmt.id} className="border-b pb-2">
              <p className="font-bold">{cmt.user_name}</p>
              <p>{cmt.comment}</p>
            </li>
          ))}
        </ul>

        {visibleComments < comments.length && (
          <button
            onClick={handleViewMore}
            className="mt-2 text-blue-600 text-sm hover:underline"
          >
            View More
          </button>
        )}
      </div>
    </div>
  );
};

export default Comment;
