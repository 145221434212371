
import { createContext, useEffect, useState } from "react";
import api from "../api/api";

// Create the context
export  const  InternshipsContext = createContext();


// Create the context provider component
export const InternshipsProvider = ({children}) => {
    const [internships, setInternships] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    //Function to fetch all internships from the API
    const fetchInternships = async () => {
        try {
            const response = await api.internships.fetchInternship();
            setInternships(response);
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);

        }
    };

    // Fetch internships when the component mounts
    useEffect(() => {
        fetchInternships();
    },[]);

    return(
        <InternshipsContext.Provider value={{internships,loading, error}} >
            {children}
        </InternshipsContext.Provider>
    )

}



