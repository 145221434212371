import React from 'react';
import { Fade } from 'react-awesome-reveal';
import Button from '../elements/Button';

export default function AboutBanner() {
  return (
    <Fade direction="up" triggerOnce>
     <section className="flex container mx-auto justify-center">
      <div className="bg-gradient-to-r from-blue-500 to-teal-500 rounded-lg shadow-xl p-6 sm:w-full lg:w-11/12 max-w-[350px] sm:max-w-full">
        <h1 className="text-xl sm:text-4xl lg:text-4xl font-bold text-white mb-3 sm:mb-4">
         Your Next Big Idea Starts Here
        </h1>
        <p className="text-sm sm:text-xs lg:text-lg text-white mb-6 sm:mb-8">
          Ready to turn your vision into reality? Let’s create something amazing together.
        </p>
        <a href="discuss-project" className="bg-white hover:bg-gray-100 text-blue-500 font-bold py-2 px-4 sm:px-6 rounded transition duration-300 ease-in-out transform hover:scale-105">
          Get in Touch
        </a>
      </div>
    </section>

    </Fade>
  );
}
