import React, { useEffect } from 'react';
import Header from 'parts/Header';
import Footer from 'parts/Footer';
import HeroServiceDetail from 'parts/HeroServiceDetail';
import ServiceDetail from 'parts/ServiceDetail';
import ProductIcon from 'parts/ProductIcon';
import ServiceTechIcon from 'parts/ServiceTechIcon';

const ServiceDetailPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <>
     <Header />
     <HeroServiceDetail />
     <ServiceTechIcon />
     <ServiceDetail />

     <Footer />
    </>
  )
}

export default ServiceDetailPage