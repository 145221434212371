import React from 'react';
import { Fade } from 'react-awesome-reveal';
import heroProduct from 'assets/images/Product/productbanner.png';

export default function HeroPortfolio() {
  return (
    <section className="hero flex flex-col sm:flex-row items-center justify-center sm:justify-between px-5 sm:px-12 lg:px-14 mt-20 ">
      <div className="flex flex-col w-full sm:w-4/5 mb-10 -mt-12">
        {/* <Fade direction="up" triggerOnce>
          <h4 className="text-[18px] text-theme-purple mb-5">Our Work</h4>
        </Fade> */}

        <Fade direction="up" triggerOnce>
          <h1 className="text-5xl lg:text-6xl text-theme-blue font-bold leading-tight mb-6">
          Innovation in Every Click <br/>
           Value in Every Feature  
          </h1>
        </Fade>

        <Fade direction="up" triggerOnce delay={400}>
          <p className="font-light text-xl text-gray-400 leading-relaxed">
          We don’t just deliver projects—we craft experiences that turn satisfaction into pure excitement!
          </p>
        </Fade>
      </div>

      <div className="w-full sm:w-1/2 flex justify-center sm:justify-end -mt-16">
        <Fade direction="up" triggerOnce>
          <img 
            src={heroProduct} 
            className="h-[500px] object-contain " 
            alt="Hero" 
          />
        </Fade>
      </div>
    </section>
  );
}
