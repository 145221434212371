
import React, { Component } from 'react';

import Header from 'parts/Header';
import Footer from 'parts/Footer';
import DiscussForm from 'parts/DiscussForm';


export default class ContactPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Header />
        <DiscussForm />
        <Footer />
      </>
    );
  }
}
