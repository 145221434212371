import React, { useEffect } from 'react';
import Header from 'parts/Header';
import Footer from 'parts/Footer';
import ProductDetailHero from 'parts/ProductDetailHero';
import ProductDetail from 'parts/ProductDetail';

const ProductDetailPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <>
    <Header />
    
    <ProductDetailHero />
    <ProductDetail />

    
    <Footer />
  </>
  )
}

export default ProductDetailPage