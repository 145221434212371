import React from 'react';
import { Fade } from 'react-awesome-reveal';
import aspiration from 'assets/images/About/aspiration.png';
import purpose from 'assets/images/About/purpose.png';

export default function VisionMission() {
  return (
    <div className="flex min-h-screen items-center justify-center mx-auto  lg:-mt-24">
      <div className="flex flex-col space-y-14 lg:flex-row lg:space-x-14 lg:space-y-0">
      <Fade bottom triggerOnce delay={500}>
        <div className="mx-auto w-[355px] h-[420px] lg:w-95 lg:h-95 rounded-2xl group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
          <div className="w-full lg:w-95 lg:h-95">
            <img className="rounded-2xl h-full w-full object-cover transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125" src={aspiration} alt="aspiration" />
          </div>
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
          <div className="absolute inset-0 flex translate-y-[60%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
            <h1 className="font-dmserif text-3xl font-bold text-white">Aspiration</h1>
            <p className="mb-3 text-lg italic text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">Our vision is to transform small towns and rural areas into thriving technology hubs. We aim to be a catalyst for regional growth by empowering young minds thereby redefining the boundaries of the tech industry.</p>
          
          </div>
        </div>
      </Fade>

      <Fade bottom triggerOnce delay={500}>
        <div className="w-[355px] h-[420px] lg:w-95 lg:h-95 rounded-2xl group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
          <div className="w-full lg:w-95 lg:h-95">
            <img className="rounded-2xl h-full w-full object-cover transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125" src={purpose} alt="purpose" />
          </div>
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
          <div className="absolute inset-0 flex translate-y-[60%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
            <h1 className="font-dmserif text-3xl font-bold text-white">Purpose</h1>
            <p className="mb-3 text-lg italic text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">Our mission at Scode Software Solutions is to decentralize the tech industry by creating high-quality IT jobs in tier-3 cities, empowering local talent to succeed in the global market.</p>
            
          </div>
        </div>
      </Fade>
      
      </div>
    </div>
  );
}
