import React from 'react';
import Fade from 'react-awesome-reveal';

export default function AboutFewReason({ data }) {
  return (
    <div className="py-20 mb-24 sm:mb-18 xl:mb-16">
      <div className="container mx-auto">
        <Fade bottom triggerOnce>
          <h1 className="text-4xl text-theme-blue text-center font-bold">Why Choose Us</h1>
          <p className="font-light text-lg text-gray-400 text-center mb-12 sm:mb-5 xl:mb-0">
           Why Our Team is Your Best Bet for Project Success.
          </p>
        </Fade>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 xl:px-2 lg:px-2 md:px-12 lg:mt-8 lg:w-11/12 mx-auto">
          {
            data.map((row, rowIndex) => (
              row.map((item, index) => (
                <Fade bottom triggerOnce delay={50 * (rowIndex * 2 + index)} key={item.id}>
                  <div className={`bg-white flex flex-row items-center p-3 mx-1 lg:mx-0 rounded-2xl shadow-xl border border-light-theme-purple transform transition duration-500 hover:scale-105 ${[2, 4, 6].includes(item.id) ? 'lg:mt-12' : ''} `}>
                    <img src={item.imageUrl} alt="" className="w-28 lg:h-28" />
                    <div className="flex-col pl-5">
                      <h2 className="text-theme-blue text-2xl">{item.title}</h2>
                      <p className="font-light text-gray-400">{item.description}</p>
                    </div>
                  </div>
                </Fade>
              ))
            ))
          }
        </div>
      </div>
    </div>
  );
}
