

import React from 'react';
import BrandLogo from "../assets/images/scode_logo1.png";
// import BrandIcon from './BrandIcon';
import Button from '../elements/Button';
import { RiInstagramFill } from "react-icons/ri";
import { BsFacebook } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

export default function Footer() {
  return (
    <div className="bg-gray-50 border-t border-gray-200 pb-6 mt-10">
      <div className="container flex-col mx-auto ">
        <div className="flex flex-col sm:flex-row mt-8 justify-center">

          <div className="flex flex-col items-center lg:items-start lg:justify-start lg:w-1/3 ml-4 lg:ml-16 lg:mr-8 hidden lg:block">
            <div className="flex flex-col items-center lg:items-center w-full mb-2"> 
              <div className="w-[100px] h-[100px] rounded-full relative">
                <img
                  className="w-[100px] h-[100px] object-cover absolute top-0"
                  src={BrandLogo}
                  alt="Build Website logo"
                />
              </div>
              <p className="w-full text-center lg:text-center text-lg text-gray-400 font-light mt-2">
                Scode Software Solutions
              </p>
            </div>
          </div>

          <div className="lg:w-1/3 mt-0 ml-16 mr-0 sm:ml-10 sm:mr-5">
            <h1 className="text-lg text-theme-blue pt-4 pb-2">
              Office Location
            </h1>
            <p className="text-lg text-gray-400 font-light">
              No.47/17, Second Floor, Sanjeevirayan Koil
            </p>
            <p className="text-lg text-gray-400 font-light">
             Street, Pudupalayam, Cuddalore - 607001.
            </p>
          </div>

          <div className="lg:w-1/3 ml-16 sm:ml-5 mt-0 ">
            <h1 className="text-lg text-theme-blue pt-4 pb-2">
             Follow Us
            </h1>
            <Button href="http://instagram.com/scodesoftwaresolutions?igsh" type="link" target="_blank" className="flex text-lg gap-1 text-gray-400 font-light hover:underline" isExternal>
            <RiInstagramFill  size={22} />
              Instagram
            </Button>
            <Button href="http://linkedin.com/company/scode-soft" type="link" target="_blank" className="flex text-lg gap-1 text-gray-400 font-light hover:underline" isExternal>
             <FaLinkedin size={22}  />
              LinkedIn
            </Button>
            <Button href="http://facebook.com/share/wmzRPfEKTC3Cp2eP/" type="link" target="_blank" className="flex text-lg gap-1 text-gray-400 font-light hover:underline" isExternal>
            <BsFacebook size={22}  />
              FaceBook
            </Button>
            <Button href="http://www.youtube.com/@ScodeSoftwareSolutions" type="link" target="_blank" className="flex text-lg gap-1 text-gray-400 font-light hover:underline" isExternal>
             <FaYoutube size={22}  />
              Youtube
            </Button>
          </div>

          <div className="lg:w-1/3 ml-16 sm:-ml-20 mt-0 ">
            <h1 className="text-lg text-theme-blue pt-4 pb-2">
            Contact Us
            </h1>
             <Button
                href="tel:+919629692276"
                type="link"
                className="flex text-lg gap-1 text-gray-400 font-light hover:underline"
                isExternal
              >
                <FaPhone />
                +91-9629692276
              </Button>
              <Button
                href="tel:+919342180679"
                type="link"
                className="flex text-lg gap-1 text-gray-400 font-light hover:underline"
                isExternal
              >
                <FaPhone />
                +91-9342180679
              </Button>
              <Button
                href="tel:+914142359225"
                type="link"
                className="flex text-lg gap-1 text-gray-400 font-light hover:underline"
                isExternal
              >
                <FaPhone />
                +91-4142-359225
              </Button>
              <Button
                href="mailto:hr@scodesoft.com"
                type="link"
                className="flex text-lg gap-1 text-gray-400 font-light hover:underline"
                isExternal
              >
                <IoMdMail size={22} />
                hr@scodesoft.com
              </Button>

          </div>

        </div>

        <div className="flex-col text-center mt-7">

          <p className="text-lg text-gray-400 font-light">
            Copyright 2024 - All rights reserved - scode software solutions
          </p>
         
        </div>
      </div>
    </div>
  );
}
