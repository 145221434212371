import React from 'react'
import { Fade } from 'react-awesome-reveal';
import whoWeServe from 'assets/images/Solution/who-we-serve.png';

import { PiStarFourFill } from "react-icons/pi";

const WhoWeServe = () => {
  return (
    <section className="hero flex flex-col sm:flex-row items-center justify-center sm:justify-between px-5 sm:px-12 lg:px-12 mt-10 ">
        <div className="flex flex-col w-full sm:w-1/2 mb-10 mt-12 ">
            <Fade direction="up" triggerOnce>
                <h1 className="text-4xl text-theme-blue font-bold leading-tight mb-6">
                Who We Serve
                </h1>
            </Fade>

            <Fade direction="up" triggerOnce delay={400}>
                <p className="font-light text-xl text-gray-400 leading-relaxed">
                At Scode Software Solutions, we serve a diverse range of clients, from budding entrepreneurs
                and small businesses to established corporations. We cater to:
                </p>
            </Fade>
            <Fade direction="up" triggerOnce>
                <img 
                src={whoWeServe} 
                className="h-[500px] object-contain " 
                alt="Hero" 
                />
            </Fade>
        </div>

        <div className="w-full sm:w-1/2 flex justify-center sm:justify-end -mt-5">
        <Fade direction="up" triggerOnce>
           <div class="p-6 mt-4">
                <ol class="relative border-s border-gray-200 dark:border-gray-700">                  
                    <li class="mb-10 ms-6">            
                        <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                        <PiStarFourFill className='text-theme-blue' />
                        </span>
                        <h3 class="flex items-center mb-1 text-lg font-semibold text-theme-blue dark:text-white">Visionary Startups </h3>
                        <p class="mb-4 text-base font-normal text-gray-400 dark:text-gray-400">Turning groundbreaking concepts into industry-disrupting apps and
                        platforms.</p>
                    </li>

                    <li class="mb-10 ms-6">
                        <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                        <PiStarFourFill className='text-theme-blue' />
                        </span>
                        <h3 class="mb-1 text-lg font-semibold text-theme-blue dark:text-white">Dynamic SMEs</h3>
                        <p class="text-base font-normal text-gray-400 dark:text-gray-400">Empowering growth through bespoke digital transformation solutions.</p>
                    </li>

                    <li class="mb-10 ms-6">
                        <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                        <PiStarFourFill className='text-theme-blue' />
                        </span>
                        <h3 class="mb-1 text-lg font-semibold text-theme-blue dark:text-white">Fortune 500 Giants</h3>
                        <p class="text-base font-normal text-gray-400 dark:text-gray-400">Delivering enterprise-level technology that scales with ambition.</p>
                    </li>

                    <li class="mb-10 ms-6">
                        <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                        <PiStarFourFill className='text-theme-blue' />
                        </span>
                        <h3 class="mb-1 text-lg font-semibold text-theme-blue dark:text-white">Educational Pioneers</h3>
                        <p class="text-base font-normal text-gray-400 dark:text-gray-400">Revolutionizing the learning experience with next-gen digital
                        tools.</p>
                    </li>

                    <li class="mb-10 ms-6">
                        <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                        <PiStarFourFill className='text-theme-blue' />
                        </span>
                        <h3 class="mb-1 text-lg font-semibold text-theme-blue dark:text-white">Mission-Driven NGOs</h3>
                        <p class="text-base font-normal text-gray-400 dark:text-gray-400">Amplifying impact through technology that transforms
                        communities.</p>
                    </li>
                </ol>
            </div>
        </Fade>
        </div>
    </section>
  )
}

export default WhoWeServe