import React, { useEffect, useState } from 'react';
import { FaThumbsUp, FaEye } from "react-icons/fa";
import api from '../api/api'; // Import your API module

const LikeShareview = ({ blogPostId, initialLikes }) => {
    const [likes, setLikes] = useState(initialLikes || 0); // State for likes
    const [views, setViews] = useState(0); // State for views
    const [isLiked, setIsLiked] = useState(false); // State to track if already liked

    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to manage dropdown visibility
    const [copyButtonText, setCopyButtonText] = useState("Copy Link"); // State for button text
    const blogPostUrl = `${window.location.origin}/blogs`; // Construct the blog post URL

    // Function to fetch views on component mount
    useEffect(() => {
        const fetchViewCount = async () => {
            try {
                const response = await api.blogs.getViewCount(blogPostId); // Call your API to get view count
                if (response && response.views) {
                    setViews(response.views); // Update views state with response
                }
            } catch (error) {
                console.error("Error fetching view count:", error.message);
            }
        };

        fetchViewCount(); // Call the function to fetch view count on mount
    }, [blogPostId]); // Dependency array includes blogPostId

    const handleLike = async () => {
        if (!isLiked) {
            try {
                const response = await api.blogs.likeBlogPost(blogPostId); // Call the API to like the post
                setLikes(response.likes); // Update likes from the API response
                setIsLiked(true); // Update state to indicate the post has been liked
            } catch (error) {
                console.error("Error liking the blog post:", error.message);
            }
        }
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown visibility
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(blogPostUrl); // Copy the blog post URL to clipboard
        setCopyButtonText("Copied"); // Change button text to "Copied"
        setIsDropdownOpen(false); // Close the dropdown after copying

        // Reset button text after a few seconds
        setTimeout(() => {
            setCopyButtonText("Copy Link");
        }, 2000); // Reset after 2 seconds
    };

    return (
        <>
            {/* Like, Comment, and Share Section */}
            <div className="flex justify-around items-center bg-gray-50 py-4 border-t border-b md:mx-24 md:px-24">
                {/* Likes */}
                <button
                    onClick={handleLike}
                    className="flex items-center space-x-1 text-gray-600 hover:text-black transition"
                    disabled={isLiked} // Disable button if already liked
                >
                    <FaThumbsUp />
                    <span className="text-sm">{likes}</span>
                </button>

                {/* Views */}
                <div className="flex items-center space-x-1 text-gray-600">
                    <FaEye />
                    <span className="text-sm">{views}</span>
                </div>

               {/* Share */}
                <div className="relative">
                    <button 
                        onClick={toggleDropdown} 
                        className="flex items-center space-x-1 text-gray-600 hover:text-black transition"
                    >
                        <span className="material-icons-outlined">share</span>
                    </button>
                    {isDropdownOpen && (
                        <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-10">
                            <button 
                                onClick={handleCopyLink} 
                                className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                            >
                                {copyButtonText} {/* Display current button text */}
                            </button>
                        </div>
                    )}
                </div>

            </div>
        </>
    );
};

export default LikeShareview;
