
import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default function CurrentProject({ data }) {
  // Check if data is provided and is an array before mapping
  const projects = data && Array.isArray(data) ? data : [];

  return (
    <section className="container mx-auto px-2 py-8">
      <Fade direction="up">
        <h1 className="text-4xl text-theme-blue text-center font-bold mb-4">Active Projects</h1>
      </Fade>

      <Fade direction="up" delay={500}>
        <p className="font-light text-lg text-gray-400 text-center mb-4">
          Discover our latest projects driving innovation and growth.
        </p>
      </Fade>

      {projects.length > 0 ? (
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          className="mySwiper"
          loop={true}
          navigation={true}
          autoplay={{ delay: 4000, disableOnInteraction: false }}
        >
          {projects.map((item) => (
            <SwiperSlide key={item.id}>
              <div className="flex flex-col sm:flex-row mx-2 mb-6 mt-6 xl:mx-auto sm:mx-6 sm:mb-12 items-center sm:pl-32">
                {/* Image Container with Shadow */}
                <div className="flex-none w-full sm:w-1/2 flex justify-center sm:justify-end relative mb-4 sm:mb-0">
                  <div className="relative">
                    <img
                      src={item.imageUrl}
                      alt={`${item.title}`}
                      className="h-[200px] w-full sm:h-[400px] sm:w-[620px] object-cover  rounded-lg"
                    />
                  </div>
                </div>

                {/* Transparent Background Div */}
                <div className="bg-white bg-opacity-90 text-gray-700 rounded-2xl w-full sm:w-[420px] flex flex-col p-4 sm:p-6">
                  <h2 className="text-theme-blue text-2xl font-bold mt-2 text-center sm:text-left">{item.title}</h2>
                  <p className="font-light text-sm sm:text-xl text-center sm:text-left text-gray-400 mt-4">
                    {item.long_description}
                  </p>

                  {/* Tech list (If applicable) */}
                  {item.tech && (
                    <div className="mt-6 flex flex-wrap gap-2 justify-center sm:justify-start">
                      {item.tech.map((techItem) => (
                        <div key={techItem.id} className="flex items-center w-auto px-3 py-1 h-8 border-2 rounded-md bg-white">
                          {techItem.icon && <techItem.icon className="sm:h-4 w-4 mr-2" />}
                          <span className="text-sm text-gray-700">{techItem.name}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <p className="text-center text-gray-400">No active projects available.</p>
      )}
    </section>
  );
}









// import React from 'react';
// import { Fade } from 'react-awesome-reveal';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
// import 'swiper/swiper-bundle.css';
// import 'swiper/css';
// import 'swiper/css/autoplay';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';


// export default function CurrentProject({ data }) {
//   const projects = Array.isArray(data) ? data : [];

//   return (
//     <section className="container mx-auto px-2 py-8">
//       <Fade direction="up">
//         <h1 className="text-4xl text-theme-blue text-center font-bold mb-4">Active Projects</h1>
//       </Fade>

//       <Fade direction="up" delay={500}>
//         <p className="font-light text-lg text-gray-400 text-center mb-4">
          
//          Discover our latest projects driving innovation and growth. 
//         </p>
//       </Fade>

//       <Swiper
//         modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
//         className="mySwiper"
//         loop={true}
//         navigation={true}
//         autoplay={{ delay: 4000, disableOnInteraction: false }}
//       >
//         {projects.map((item) => (
//           <SwiperSlide key={item.id}>
//             <div className="flex flex-col sm:flex-row mx-2 mb-6 mt-6 xl:mx-auto sm:mx-6 sm:mb-12 items-center sm:pl-32">

//               {/* Image Container with Shadow */}
//               <div className="flex-none w-full sm:w-1/2 flex justify-center sm:justify-end relative mb-4 sm:mb-0">
//                 <div className="relative">
//                   <img
//                     src={item.imageUrl}
//                     alt={`${item.title}'s testimonial`}
//                     className="h-[200px] w-full sm:h-[400px] sm:w-[620px] object-cover  rounded-lg"
//                   />

//                 </div>
//               </div>

//               {/* Transparent Background Div */}
//               <div className="bg-white bg-opacity-90 text-gray-700 rounded-2xl w-full sm:w-[420px] flex flex-col p-4 sm:p-6">
//                 <h2 className="text-theme-blue text-2xl font-bold mt-2 text-center sm:text-left">{item.title}</h2>
//                 <p className="font-light text-sm sm:text-xl text-center sm:text-left text-gray-400 mt-4">
//                   {item.long_description}
//                 </p>

//                 {/* Tech list with rectangle boxes */}
//                 <div className="mt-6 flex flex-wrap gap-2 justify-center sm:justify-start">
//                   {item.tech.map((techItem) => (
//                     <div key={techItem.id} className="flex items-center w-auto px-3 py-1 h-8 border-2 rounded-md bg-white">
//                       {techItem.icon ? <techItem.icon className="sm:h-4 w-4 mr-2" /> : null}
//                       <span className="text-sm text-gray-700">{techItem.name}</span>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           </SwiperSlide>
//         ))}
//       </Swiper>
//     </section>
//   );
// }