import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import emailjs from 'emailjs-com'; // Import emailjs library
import { toast } from 'react-toastify'; // Import toast for notifications

const JobApplicationForm = () => {
  const location = useLocation();
  const job = location.state;

  // Form state for name, email, phone, and message
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  // Handle input changes for the form
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare email parameters for emailjs
    const emailParams = {
      from_name: formData.name,
      from_email: formData.email,
      phone: formData.phone,
      message: formData.message,
    };

    // Send email using emailjs
    emailjs.send('service_hob28ca', 'template_pisiiag', emailParams, 'rsVBSv1CaAIgW6JM7')
      .then(
        (response) => {
          toast.success('Application submitted successfully!', { autoClose: 3000 });

          // Clear form fields after submission
          setFormData({
            name: '',
            email: '',
            phone: '',
            message: '',
          });
        },
        (err) => {
          toast.error('There was an error submitting the application.', { autoClose: 3000 });
        }
      );
  };

  return (
    <div className="container mx-auto">
      <form onSubmit={handleSubmit} className="max-w-md mx-auto bg-white p-8 rounded-lg shadow-lg">
        {/* Name Input */}
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>

        {/* Email Input */}
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>

        {/* Phone Input */}
        <div className="mb-4">
          <label htmlFor="phone" className="block text-gray-700">Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>

        {/* Message Textarea */}
        <div className="mb-4">
          <label htmlFor="message" className="block text-gray-700">Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            rows="4"
            required
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="bg-theme-purple text-white px-4 py-2 rounded-full transition duration-300"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default JobApplicationForm;
